import { useTheme } from '@emotion/react';
import InfoIcon from '@mui/icons-material/Info';
import { LatLng } from 'dataTypes/common';
import useClasses from 'hooks/useClasses';
import React, { MouseEventHandler, useState, useMemo, useCallback } from 'react';
import { OverlayView } from '@react-google-maps/api';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useCachedQueryRequest from 'hooks/useCachedQueryRequest';
import moment from 'moment';
import TooltipContents from 'TrackAndTrace/Assets/components/AssetTooltip/TooltipContents';
import { ExtractedAssetData } from 'TrackAndTrace/Assets/lib';
import { GatewayInfoDTO } from 'dataTypes/SecureBackend/apiResponse';
import useGetGatewaysByGatewayNumbers, {
    extractLatestSensorDataWithUniqueGatewayNumbers,
} from 'hooks/useGetGatewaysByGatewayNumbers/useGetGatewaysByGatewayNumbers';
import UncontrolledTooltip from '../../../../shared-components/ControlledTooltip/UncontrolledTooltip';

type Props = {
    assetGroup: ExtractedAssetData[],
    onMouseLeave: MouseEventHandler<HTMLDivElement>,
    zoom: number,
    position: LatLng,
}

const ZOOM_THRESHOLD = 5;

const styles = (theme: SkycellThemeInterface) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        paddingLeft: '40px',
        minWidth: '100%',
        width: 'max-content',
    },
    info: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        padding: '4px 8px',
        gap: '10px',
        color: 'black',
        fontSize: '14px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        height: '66px',
        width: '100%',
        zIndex: 1000,
    },
    infoIcon: {
        fontSize: '20px',
        display: 'flex',
        alignItems: 'center',
    },
    loggerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
        position: 'relative',
        borderRadius: '4px',
        zIndex: 100,
        backgroundColor: theme.palette.common.white,
        minWidth: '100%',
        width: 'max-content',
    },
    airportCount: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'space-between',
        padding: '4px 8px',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        color: 'black',
        fontSize: '26px',
        userSelect: 'none',
        height: '50px',
        width: '100%',
    },
    headerText: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        textDecoration: 'underline',
        minWidth: '100%',
        width: 'max-content',
        fontSize: '32px',
        padding: '8px 30px',
        letterSpacing: '0.01em',
        textAlign: 'left',
        color: theme.palette.common.black,
    },
    body: {},
    division: {
        padding: '14px 14px 30px 14px',
        borderTop: '1px solid #dbdbdb',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '14px',
        width: '100%',
    },
});
// 2023-09-12T13:58
const AssetTooltip = ({
    assetGroup = [],
    onMouseLeave,
    position,
    zoom,
}: Props) => {
    const classes = useClasses(styles);
    const [hoveredAssetId, setHoveredAssetId] = useState<number | null>(null);
    const theme = useTheme();
    const {
        Get: getLoggerSensorData,
    } = useSecureBackendEndpoints('assets').requests;
    const showAirports = useMemo(() => zoom <= ZOOM_THRESHOLD, [zoom]);
    const {
        data: sensorData,
        status: sensorDataStatus,
    } = useCachedQueryRequest({
        key: `${hoveredAssetId}_sensor_data`,
        request: getLoggerSensorData.bind(null, `${
            hoveredAssetId
        }/sensor-data?from=${
            moment().utc().subtract(7, 'day').format('YYYY-MM-DDTHH:mm')
        }&to=${moment().utc().format('YYYY-MM-DDTHH:mm')}`),
        options: {
            enabled: !!hoveredAssetId,
            retry: false,
        },
    });

    const uniqueLastSensorData = useMemo(() => {
        return extractLatestSensorDataWithUniqueGatewayNumbers(sensorData);
    }, [sensorData]);

    const {
        gateways = [],
    } = useGetGatewaysByGatewayNumbers({
        query: {
            gatewayImeiMac: uniqueLastSensorData.map(it => it.gatewayNumber),
        },
        enabled: uniqueLastSensorData.length !== 0,
    });

    const lastGateways = useMemo<{
        gateway: GatewayInfoDTO,
        t: string,
    }[]>(() => {
        if (hoveredAssetId && uniqueLastSensorData.length !== 0 && gateways) {
            return uniqueLastSensorData.map(it => ({
                gateway: gateways?.find(g => g?.gatewayImeiMac?.toLowerCase() === it?.gatewayNumber?.toLowerCase()),
                t: it?.sensorData?.t,
            }));
        } else return [];
    }, [uniqueLastSensorData, hoveredAssetId, gateways]);

    const getLatestStoredGateway = useCallback<(asset:ExtractedAssetData) => {
        gateway: GatewayInfoDTO,
        t: string,
    }[]>((asset) => {
            return [{
                gateway: {
                    latitude: asset.lastMeasuredLatitude,
                    longitude: asset.lastMeasuredLongitude,
                    iataCode: asset.iataCode,
                    area: asset.area || asset.locationName,
                },
                t: moment(asset.lastMeasuredTimestamp).format('YYYY.MM.DD HH:mm'),
            }];
        }, [assetGroup]);

    const assetGroupSortedAndSliced = useMemo(() => {
        return assetGroup.sort((
            a,
            b,
        ) => a.lastMeasuredTimestamp - b.lastMeasuredTimestamp).slice(-5).reverse();
    }, [assetGroup]);

    const assetAirports = useMemo(() => {
        return assetGroup.reduce((acc, asset) => {
            const airport = asset.iataCode || asset.locationName || 'Unknown';

            if (acc[airport]) {
                acc[airport].push(asset);
            } else {
                acc[airport] = [asset];
            }
            return acc;
        }, {});
    }, [assetGroup]);

    return (
        <OverlayView
            mapPaneName={OverlayView.FLOAT_PANE}
            zIndex={100}
            position={position}
        >
            <div>
                {
                    !showAirports && (
                        <div
                            className={classes.root}
                            style={{
                                transform: `translateY(${assetGroup.length > 5 ? -74 : 0}px)`,
                            }}
                            onMouseLeave={onMouseLeave}
                        >
                            { assetGroup.length > 5 && (
                                <div className={classes.info}>
                                    <div className={classes.infoIcon}>
                                        <InfoIcon style={{
                                            color: theme.palette.primary.deepBlue,
                                            fontSize: '30px',
                                        }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            whiteSpace: 'wrap',
                                        }}
                                    >
                                        5 most recent of
                                        {' '}
                                        {assetGroup.length}
                                        {' '}
                                        assets shown.
                                        {' '}
                                        <br />
                                        View full list in table view.
                                    </div>
                                </div>
                            )}
                            {
                                assetGroupSortedAndSliced.map((asset) => (
                                    <TooltipContents
                                        key={`asset_on_map_${asset.id}_${asset.assetNumber}`}
                                        classes={classes}
                                        asset={asset}
                                        setHoveredAssetId={setHoveredAssetId}
                                        hoveredAssetId={hoveredAssetId}
                                        sensorDataStatus={sensorDataStatus}
                                        lastGateways={lastGateways}
                                        getLatestStoredGateway={getLatestStoredGateway}
                                    />
                                ))
                            }
                        </div>
                    )
                }
                {
                    showAirports && (
                        <div
                            className={classes.root}
                            style={{
                                transform: 'translateY(-74px)',
                            }}
                            onMouseLeave={onMouseLeave}
                        >
                            <div
                                className={classes.info}
                            >
                                <div className={classes.infoIcon}>
                                    <InfoIcon style={{
                                        color: theme.palette.primary.deepBlue,
                                        fontSize: '30px',
                                    }}
                                    />
                                </div>
                                <div style={{
                                    whiteSpace: 'wrap',
                                }}
                                >
                                    Showing the number of
                                    <br />
                                    assets per location.
                                    <br />
                                    View full list in table view.
                                </div>
                            </div>

                            {
                                Object.keys(assetAirports).map((iataCode) => (
                                    <div
                                        className={classes.airportCount}
                                        key={`assets_map_airport_${iataCode}`}
                                    >
                                        <div>
                                            <UncontrolledTooltip
                                                description={iataCode}
                                                shrinked
                                                placement="left"
                                                enabled={iataCode.length > 10}
                                            >
                                                <span
                                                    style={{
                                                        display: 'block',
                                                        overflow: 'hidden',
                                                        maxWidth: '200px',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {iataCode}
                                                </span>
                                            </UncontrolledTooltip>
                                        </div>
                                        <div>
                                            {assetAirports[iataCode].length}
                                        </div>
                                    </div>

                                ))
                            }
                        </div>
                    )
                }
            </div>
        </OverlayView>

    );
};

export default AssetTooltip;
