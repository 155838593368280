/* eslint-disable max-len */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import Card from 'shared-components/Card';
import useClasses from '../../hooks/useClasses';

const styles = {
    contentClass: {
        width: '100%',
        height: '100%',
    },
};

const LaneComparison = () => {
    const classes = useClasses(styles);

    return (
        <Card fullHeight contentClass={classes.contentClass}>
            <iframe
                width="100%"
                height="100%"
                src="https://xd.adobe.com/embed/f2802e8c-5b4f-46c7-9555-918b49a98e07-a7d3/screen/f656df72-c342-4082-a63f-bc5b5a61a746/?fullscreen"
                frameBorder="0"
                allowFullScreen
            />
        </Card>
    );
};

export default LaneComparison;
