import { TFunction } from 'i18next';
import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import LastConnect from 'shared-components/LastConnect';
import LatitudeLongitudeWithPlaceInfo from 'shared-components/Table/CellComponents/LatitudeLongitudeWithPlaceInfo';
import { ExtractedLoggerData } from 'TrackAndTrace/Loggers/lib';
import EnergyLevel from '../../shared-components/EnergyLevel';

const tableColumns: (
    t:TFunction, trackAndTrace: boolean
) => ColumnsType<ExtractedLoggerData>[] = (t, trackAndTrace) => [
    {
        Header: t('COMMON.LOGGER_NUMBER'),
        accessor: 'loggerNumber',
        dataType: 'highlightedText',
    },
    {
        id: 'loggerTypeCode',
        Header: t('COMMON.TYPE'),
        accessor: 'loggerFamilyLabel',
        dataType: 'text',
    },
    // {
    //     Header: t('COMMON.PACKAGING'),
    //     accessor: 'containerSerialNumber',
    //     dataType: 'text',
    //     filterKey: 'containerSerialNumber',
    //     filterType: 'text',
    // },
    // {
    //     Header: t('COMMON.SHIPMENT'),
    //     accessor: 'currentOrderOrderNumber',
    //     dataType: 'custom',
    //     filterKey: 'currentOrderOrderNumber',
    //     filterType: 'enum',
    // },
    {
        id: 'lastMeasuredData.temperature',
        Header: t('COMMON.TEMPERATURE'),
        accessor: (row) => row.lastMeasuredTempStr,
        dataType: 'text',
    },
    {
        id: 'lastMeasuredData.temperatureGeolocationTimestamp',
        Header: t('TRACK_AND_TRACE.LAST_UPDATED'),
        accessor: (row) => <LastConnect timestamp={row.lastMeasuredTimestamp} />,
        dataType: 'custom',
    },
    {
        id: 'lastMeasuredData.geolocation',
        Header: t('TRACK_AND_TRACE.LATITUDE_LONGITUDE'),
        accessor: (row) => (
            <LatitudeLongitudeWithPlaceInfo
                lat={row.lastMeasuredLatitude}
                lng={row.lastMeasuredLongitude}
            />
        ),
        dataType: 'custom',
        sortType(rowA, rowB) {
            const aLat = rowA.original.lastMeasuredLatitude;
            const bLat = rowB.original.lastMeasuredLatitude;
            const aLong = rowA.original.lastMeasuredLongitude;
            const bLong = rowB.original.lastMeasuredLongitude;

            if (aLat === bLat) {
                return aLong - bLong;
            }

            return aLat - bLat;
        },
    },
    {
        id: 'batteryLevel',
        Header: t('COMMON.BATTERY'),
        accessor: (row) => (
            (
                row.batteryLevel >= 0 && row.batteryLevel <= 100 && row.batteryLevel !== null ? (
                    <EnergyLevel
                        threshold
                        energyLevel={Number(((row.batteryLevel) * 100).toFixed(0))}
                    />
                ) : ''
            )),
        dataType: 'custom',
        customCellStyle: {
            whiteSpace: 'pre-wrap',
            alignItems: 'center',
            maxWidth: '230px',
        },
        disableSortBy: false,
    },
    ...(!trackAndTrace ? [{
        id: 'pairingStatus',
        Header: t('COMMON.PAIRING_STATUS'),
        accessor: 'pairingStatus',
        dataType: 'text',
        disableSortBy: true,
    }] : []),
];

export default tableColumns;
