import useClasses from 'hooks/useClasses';
import React, { useContext } from 'react';
import {
    Route, Routes,
} from 'react-router-dom';
import { GuideRouteMapping } from 'UserGuides/GuideRouteMapping';
import { docsPath } from 'shared-components/constants';
import NoUserGuide from 'UserGuides/NoUserGuide';
import useCustomTranslation from 'hooks/useCustomTranslation';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { styles } from './UserGuide.style';
import { QuickHelpContext } from '../QuickHelpContext';
import HasUserGuide from '../../../UserGuides/HasUserGuide';

const UserGuide = () => {
    const classes = useClasses(styles);
    const { enableGuide } = useContext(QuickHelpContext);
    const { i18n } = useCustomTranslation();

    return (
        <Routes>
            {
                GuideRouteMapping.map((item, index) => (
                    <Route
                        key={`GuideRouteMapping-${index}`}
                        path={item.path as string}
                        element={(
                            <>
                                {
                                    item.element
                                }
                                {
                                    enableGuide && item.docPath && (
                                        <DocViewer
                                            key={`${docsPath}${i18n.language}/${item.docPath}`}
                                            className={classes.document}
                                            pluginRenderers={DocViewerRenderers}
                                            prefetchMethod="GET"
                                            config={{
                                                header: {
                                                    disableHeader: true,
                                                },
                                                pdfZoom: {
                                                    defaultZoom: 1.5,
                                                    zoomJump: 0.1,
                                                },
                                            }}
                                            documents={[
                                                { uri: `${docsPath}${i18n.language}/${item.docPath}` },
                                            ]}
                                        />
                                    )
                                }
                                <HasUserGuide />
                            </>
                        )}
                    />
                ))
            }
            <Route path="*" element={<NoUserGuide />} />
        </Routes>
    );
};

export default UserGuide;
