import { initialUserFiltersAdmin, initialUserFiltersUser } from 'Administration/lib';
import UserFilterPanel from 'Administration/Users/components/UserFilters';
import { FacetDTO } from 'dataTypes/SecureBackend/apiResponse';
import useAvailableHeight from 'hooks/useAvailableHeight';
import useClasses from 'hooks/useClasses';
import useGetCommonData from 'hooks/useGetCommonData';
import React, {
    useCallback, useEffect,
    useMemo, useState,
} from 'react';
import { PageWithFilter } from 'Layout';
import Table from 'shared-components/Table';

import useCustomTranslation from 'hooks/useCustomTranslation';
import { styles } from 'TrackAndTrace/Assets/Assets.style';
import StandalonePagination from 'shared-components/StandalonePagination';
import { getOptionsFromFacets } from 'TrackAndTrace/Assets/lib';
import { CustomSort, trimQuery } from 'TrackAndTrace/lib';
import DataPaginationContext from 'Contexts/DataPaginationContext/DataPaginationContext';
import tableColumns from './tableColumns';

const PAGE_SIZE = 20;
const Users = ({ isAdmin = true }) => {
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const [page, setPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
    const [users, setUsers] = useState([]);
    const [query, setQuery] = useState('');
    const {
        data: rawInitialFacets,
    } = useGetCommonData<FacetDTO[]>(`${isAdmin ? 'admin/' : ''}users/search/facets`,
        {
            cache: true,
            query: {
                initial: true,
            },
            postProcess: data => (Array.isArray(data) ? data : [data]),
        });
    const availableHeight = useAvailableHeight();
    const [sort, setSort] = useState<CustomSort>(null);

    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);
    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>(isAdmin ? initialUserFiltersAdmin : initialUserFiltersUser);

    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>(`${isAdmin ? 'admin/' : ''}users/search/facets`,
        {
            cache: true,
            query: {
                ...(trimQuery(filterOptions, query, rawInitialFacets)),
            },
            queryWrap: false,
            postProcess: data => (Array.isArray(data) ? data : [data]),
        });

    const {
        data: rawUsers, status: usersStatus,
    } = useGetCommonData<any>(`${isAdmin ? 'admin/' : ''}users/search`, {
        cache: true,
        query: {
            page: page - 1,
            pageSize: PAGE_SIZE,
            ...(trimQuery(filterOptions, query, rawInitialFacets, sort, rawFacets)),
        },
        enabled: facetsStatus === 'SUCCESS',
        queryWrap: false,
    });

    useEffect(() => {
        if (usersStatus === 'SUCCESS' && rawUsers?.resultList) {
            const allUsers = rawUsers?.resultList;

            setTotalElements(rawUsers.totalElements);
            setUsers(allUsers);
        }
    }, [usersStatus, rawUsers]);

    useEffect(() => {
        if (rawInitialFacets) {
            setFilterOptions({
                userRoles: getOptionsFromFacets(rawInitialFacets, 'userRoles'),
                ...(isAdmin ? { companyNames: getOptionsFromFacets(rawInitialFacets, 'companyNames') } : {}),
            });
        }
    }, [rawInitialFacets, isAdmin]);

    useEffect(() => {
        if ((page - 1) * PAGE_SIZE >= totalElements && page !== 1) setPage(Math.ceil(totalElements / PAGE_SIZE));
    }, [page, totalElements]);

    const columns = useMemo(() => tableColumns(t, isAdmin), [isAdmin]);

    return (
        <DataPaginationContext.Provider value={{
            page,
            setPage,
            perPage: PAGE_SIZE,
            totalElements,
            paginationLoading: usersStatus === 'PENDING',
        }}
        >
            <PageWithFilter>
                <UserFilterPanel
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    facets={rawFacets}
                    countsLoading={facetsStatus === 'PENDING'}
                    setQuery={setQuery}
                    query={query}
                    isAdmin={isAdmin}
                />
                <div style={{ display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    maxHeight: availableHeight,
                    paddingTop: '10px',
                }}
                >

                    <Table
                        columns={columns}
                        data={users}
                        tableMaxHeight="100%"
                        rowLinkTemplate={`/${isAdmin ? 'administration' : 'my-company'}/users/:id`}
                        title={t('MENU_ITEMS.USERS')}
                        classNames={{
                            tableContainerClassName: classes.filteredTable,
                        }}
                        maskForHighlight={query}
                        currentSort={sort}
                        onSort={updateSort}
                    />

                    <StandalonePagination detached entity={t('MENU_ITEMS.USERS')} />
                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>
    );
};

export default Users;
