import useClasses from 'hooks/useClasses';
import React, {
    useEffect,
    useMemo,
    useState, useCallback,
} from 'react';
import { FacetDTO, LoggersDTO } from 'dataTypes/SecureBackend/apiResponse';
import useGetCommonData from 'hooks/useGetCommonData';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { PageWithFilter } from 'Layout';
import TitleWithMapTableSwitch from 'shared-components/TitleWithMapTableSwitch';
import Table from 'shared-components/Table';
import useGetViewTypeFromQuery from 'TrackAndTrace/localHooks/useGetViewTypeFromQuery';

import { styles } from 'TrackAndTrace/Loggers/Loggers.style';
import DataPaginationContext from 'Contexts/DataPaginationContext';
import { CustomSort, trimQuery } from 'TrackAndTrace/lib';
import { getOptionsFromFacets } from 'TrackAndTrace/Assets/lib';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import StandalonePagination from 'shared-components/StandalonePagination';
import useFilter, { PAGES } from 'Contexts/useFilter';
import {
    extractLoggerData,
    ExtractedLoggerData,
    initialFilterOptions,
} from './lib';

import LoggerFilterPanel from './components/LoggerFilterPanel';
import MapView from './MapView';
import tableColumns from './tableColumns';
import { MAP_CONFIG } from '../../shared-components/constants';

const PAGE_SIZE = 10;

const Loggers = ({ core = 'track-and-trace' }) => {
    const view = useGetViewTypeFromQuery();
    const { t } = useCustomTranslation();
    const { getFilter, setFilter } = useFilter(PAGES.LOGGERS);
    const filter = getFilter();
    const [zoom, setZoom] = useState(MAP_CONFIG.zoom);
    const [page, setPage] = useState(filter?.page || 1);
    const [totalElements, setTotalElements] = useState(filter?.totalElements || 0);
    const loggerTypeLabels = useGetTranslationGroup('LOGGER_TYPE_LABEL');
    const pairingStatusLabels = useGetTranslationGroup('PAIRING_STATUS');
    const [highlightedText, setHighlightedText] = useState('');
    const classes = useClasses(styles);
    const [query, setQuery] = useState(filter?.query || '');
    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>(filter?.filterOptions || initialFilterOptions);

    const {
        data: rawInitialFacets,
    } = useGetCommonData<FacetDTO[]>('loggers/search/facets',
        {
            cache: true,
            query: {
                initial: true,
            },
        });

    const [sort, setSort] = useState<CustomSort>(filter?.sort || null);

    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);

    useEffect(() => {
        if (view === 'map') {
            setPage(1);
        }
        setZoom(MAP_CONFIG.zoom);
    }, [view]);

    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>('loggers/search/facets',
        {
            cache: true,
            query: {
                ...(trimQuery(filterOptions, query, rawInitialFacets)),
            },
            queryWrap: false,
        });

    const {
        data: rawLoggers, status: loggersStatus,
    } = useGetCommonData<LoggersDTO>('loggers/search',
        {
            cache: true,
            query: {
                page: page - 1,
                pageSize: PAGE_SIZE,
                ...(trimQuery(filterOptions, query, rawInitialFacets, sort, rawFacets)),
            },
            enabled: facetsStatus === 'SUCCESS',
            queryWrap: false,
        });

    const changeHighlightedText = (newHighlightedText: string) => {
        setHighlightedText(newHighlightedText);
    };

    const [loggers, setLoggers] = useState<ExtractedLoggerData[]>([]);
    const [showLoggerTypes, setShowLoggerTypes] = useState(filter?.showLoggerTypes || false);
    const [showLastConnection, setShowLastConnection] = useState(filter?.showLastConnection || false);
    const [showAirportsInfo, setShowAirportsInfo] = useState(filter?.showAirportsInfo || false);
    const [loggersFromPreviewTable, setLoggersFromPreviewTable] = useState<ExtractedLoggerData[]>(null);

    useEffect(() => {
        if (loggersStatus === 'SUCCESS' && rawLoggers?.resultList) {
            const allLoggers = extractLoggerData(rawLoggers?.resultList, loggerTypeLabels, pairingStatusLabels);

            setTotalElements(rawLoggers.totalElements);
            setLoggers(allLoggers);
        }
    }, [loggersStatus, rawLoggers, loggerTypeLabels]);

    useEffect(() => {
        if (Object.values(filterOptions).some(options => options.length !== 0)) return;

        if (rawInitialFacets) {
            setFilterOptions({
                loggerTypeCodes: getOptionsFromFacets(rawInitialFacets, 'loggerTypeCodes'),
                ...(core !== 'track-and-trace'
                    ? { pairingStatus: getOptionsFromFacets(rawInitialFacets, 'pairingStatus') } : {}),
            });
        }
    }, [rawInitialFacets, core]);

    const columns = useMemo(() => tableColumns(
        t,
        core === 'track-and-trace',
    ), [t, core]);

    useEffect(() => {
        if ((page - 1) * PAGE_SIZE > totalElements) setPage(Math.ceil(totalElements / PAGE_SIZE));
    }, [page, totalElements]);

    const withoutGeolocationCount = useMemo(() => {
        return loggers?.filter(logger => !logger.lastMeasuredLatitude || !logger.lastMeasuredLongitude)?.length || 0;
    }, [loggers]);

    useEffect(() => {
        setFilter({
            page,
            totalElements,
            query,
            filterOptions,
            sort,
            showLoggerTypes,
            showLastConnection,
            showAirportsInfo,
        });
    }, [page, totalElements, query, filterOptions, sort, showLoggerTypes, showLastConnection, showAirportsInfo]);

    return (
        <DataPaginationContext.Provider value={{
            page,
            setPage,
            perPage: PAGE_SIZE,
            totalElements,
            paginationLoading: loggersStatus === 'PENDING',
        }}
        >
            <PageWithFilter>
                <LoggerFilterPanel
                    filterOptions={filterOptions}
                    changeHighlightedText={changeHighlightedText}
                    setShowLoggerTypes={setShowLoggerTypes}
                    setShowLastConnection={setShowLastConnection}
                    setShowAirportsInfo={setShowAirportsInfo}
                    setFilterOptions={setFilterOptions}
                    loggersFromPreviewTable={loggersFromPreviewTable}
                    setLoggersFromPreviewTable={setLoggersFromPreviewTable}
                    isTableView={view === 'table'}
                    showLastConnection={showLastConnection}
                    showLoggerTypes={showLoggerTypes}
                    showAirports={showAirportsInfo}
                    query={query}
                    initialFacets={rawInitialFacets}
                    setQuery={setQuery}
                    countsLoading={facetsStatus === 'PENDING'}
                    facets={rawFacets}
                    core={core}
                />
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <TitleWithMapTableSwitch
                        isTableView={view === 'table'}
                        pathname={`/${core}/loggers`}
                    />
                    {view === 'table'
                        ? (
                            <Table
                                columns={columns}
                                data={loggers}
                                title={t('MENU_ITEMS.TRACK_AND_TRACE_LOGGERS')}
                                rowLinkTemplate={`/${core}/loggers/:loggerNumber`}
                                maskForHighlight={highlightedText}
                                classNames={{
                                    tableContainerClassName: classes.filteredTable,
                                }}
                                currentSort={sort}
                                onSort={updateSort}
                            />
                        )
                        : (
                            <MapView
                                loggers={loggers.filter(it => it.lastMeasuredLongitude && it.lastMeasuredLatitude)}
                                showAirportsInfo={showAirportsInfo}
                                showLoggerTypes={showLoggerTypes}
                                showLastConnection={showLastConnection}
                                core={core}
                                countOfLoggersWithoutGeolocation={withoutGeolocationCount}
                                zoom={zoom}
                                setZoom={setZoom}
                            />
                        )}
                    {
                        view === 'table' && <StandalonePagination detached entity="Loggers" />
                    }
                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>
    );
};

export default Loggers;
