import { CircularProgress, Collapse } from '@mui/material';
import useCustomTranslation from 'hooks/useCustomTranslation';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import GatewayStepLocation from 'TrackAndTrace/Assets/components/AssetTooltip/GatewayStep';

const TooltipContents = ({
    classes,
    asset,
    setHoveredAssetId,
    hoveredAssetId,
    sensorDataStatus,
    lastGateways,
    getLatestStoredGateway,
}) => {
    const {
        t: trans,
    } = useCustomTranslation();
    const link = useMemo(() => `/asset-management/assets/location/${asset.assetNumber}`, [asset]);
    const {
        assetNumber,
        id,
    } = asset || {};

    return (
        <div
            className={classes.loggerWrapper}
            key={assetNumber}
        >
            <Link to={link}>
                <div
                    className={classes.headerText}
                    onMouseEnter={() => setHoveredAssetId(id)}
                >
                    {assetNumber}
                </div>
            </Link>
            <Collapse
                in={hoveredAssetId === id}
            >
                <div className={classes.division}>
                    <span style={{
                        paddingBottom: '30px',
                        fontSize: '14px',
                    }}
                    >
                        <span>
                            {trans('TRACK_AND_TRACE.RECENT_LOCATIONS')}
                            <br />
                            {
                                lastGateways.length > 0 && (
                                    <span style={{
                                        fontSize: '12px',
                                    }}
                                    >
                                        (
                                        {trans('COMMON.BASED_ON_LAST_7_DAYS')}
                                        )
                                    </span>
                                )
                            }
                        </span>
                    </span>
                    {
                        !(sensorDataStatus === 'success' && lastGateways.length > 0)
                        && !(sensorDataStatus === 'error'
                            || (sensorDataStatus === 'success' && lastGateways.length === 0))
                        && <CircularProgress size={16} />
                    }
                    {
                        sensorDataStatus === 'success' && lastGateways.length > 0 && lastGateways
                            .map(({ gateway, t: time }, i) => (
                                <GatewayStepLocation
                                    key={`${assetNumber}_${time}_${gateway?.latitude}`}
                                    dash={i !== 0}
                                    gateway={gateway}
                                    time={time}
                                    loading={false}
                                    tooltipType="B"
                                />
                            ))
                    }
                    {
                        (sensorDataStatus === 'error'
                            || (sensorDataStatus === 'success' && lastGateways.length === 0)) && (
                            getLatestStoredGateway(asset).map(({ gateway, t: time }) => (
                                <GatewayStepLocation
                                    key={`${gateway.iataCode}${gateway.remark}`}
                                    gateway={gateway}
                                    time={time}
                                    loading={false}
                                    tooltipType="A"
                                />
                            ))
                        )
                    }

                </div>
            </Collapse>
        </div>
    );
};

export default TooltipContents;
