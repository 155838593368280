import React, {
    useEffect, useMemo,
    useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { RequestStatus } from 'dataTypes/common';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import loadGoogleCharts from 'utils/loadGoogleCharts';
import StatusInfo from 'shared-components/StatusInfo';
import { GenericCargo, Milestones } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import { LaneObject } from 'shared-components/dataTypes';
import { bakeLane } from 'LaneManagement/AddLane/Cards/SummaryCard/lib';
import useHasAccess from 'hooks/useHasAccess';
import {
    ShipmentDetailsInterface,
} from './lib';
import GenericShipmentDetails from './GenericShipmentDetails';
import useGetEntity from '../../hooks/useGetEntity';
import { adminApi } from '../../utils/stringTool';

type PathParams = {
    id: string,
}

const GenericShipmentDetailsContainer = ({ core = 'track-and-trace' }) => {
    loadGoogleCharts();
    const { id } = useParams<PathParams>();
    const hasAccess = useHasAccess();
    const asAdmin = useMemo(() => core === 'administration', [core]);

    const { Get: getShipment } = useSecureBackendEndpoints(adminApi('v2/shipments', asAdmin)).requests;
    const { Get: getShipmentCargo } = useSecureBackendEndpoints(adminApi(`v2/shipments/${id}/cargo`, asAdmin)).requests;
    const { Get: getLaneById } = useSecureBackendEndpoints(adminApi('lanes', asAdmin)).requests;
    const { Get: getShipmentMilestones } = useSecureBackendEndpoints(
        adminApi(`v2/shipments/${id}/milestones`, asAdmin)).requests;
    const { setBreadcrumbData } = useBreadcrumbsContext();

    useEffect(() => {
        setBreadcrumbData('SHIPMENT_NUMBER', id);
    }, [id]);

    const [shipment, setShipment] = useState<ShipmentDetailsInterface>(null);
    const [status, setStatus] = useState<RequestStatus>('INIT');
    const [requiredOrderDataUpdate, setRequiredOrderDataUpdate] = useState(false);
    const [cargo, setCargo] = useState<GenericCargo[]>(null);
    const [lane, setLane] = useState<LaneObject>(null);
    const [milestones, setMilestones] = useState<Milestones[]>(null);
    const {
        replaceWithAddressDTO, entityFromAddressId, getEntity,
    } = useGetEntity();

    useEffect(() => {
        if (status !== 'PENDING') {
            (async () => {
                try {
                    setStatus('PENDING');
                    const response = (await getShipment(id)).data;
                    const responseCargo = (await getShipmentCargo(' ')).data;
                    const responseMilestones = (await getShipmentMilestones(' ')).data;

                    if (response) {
                        setShipment(response);
                        setCargo(responseCargo);
                        setMilestones(responseMilestones);
                        setStatus('SUCCESS');
                    }
                } catch (error) {
                    setStatus('FAILURE');
                    global.console.log(error);
                }
            })();
        }
    }, [requiredOrderDataUpdate]);

    useEffect(() => {
        const fetchLaneData = async () => {
            if (hasAccess('LANE_MANAGEMENT') && shipment && shipment.laneId !== null) {
                try {
                    const laneResponse = await getLaneById(shipment.laneId);

                    setLane(bakeLane(
                        laneResponse.data,
                        replaceWithAddressDTO,
                        getEntity,
                        entityFromAddressId,
                    ));
                } catch (laneError) {
                    if (laneError.response && laneError.response.status === 403) {
                        global.console.log('Lane request returned 403, ignoring:', laneError);
                    } else {
                        global.console.log('Error fetching lane:', laneError);
                    }
                }
            } else {
                setLane(null);
            }
        };

        if (lane === null) fetchLaneData();
    }, [shipment]);

    return status === 'SUCCESS'
        ? (
            <>
                <GenericShipmentDetails
                    shipmentId={id}
                    asAdmin={asAdmin}
                    shipment={shipment}
                    milestones={milestones}
                    lane={lane}
                    cargo={cargo}
                    setRequiredOrderDataUpdate={setRequiredOrderDataUpdate}
                />
            </>
        )
        : <StatusInfo status={status} />;
};

export default GenericShipmentDetailsContainer;
