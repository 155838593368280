import { useCallback } from 'react';
import { GatewayInfoDTO, SensorDataItem, SensorDataResponse } from '../../dataTypes/SecureBackend/apiResponse/index';
import { ExtractedAssetData } from '../../TrackAndTrace/Assets/lib';
import useCachedQueryRequest from '../useCachedQueryRequest/index';
import useSecureBackendEndpoints from '../useSecureBackendEndpoints/index';
import { getQueriesUnwrapped } from '../useSecureBackendEndpoints/lib';

type Props = {
    query?: object,
    enabled?: boolean,
}

export const extractLatestSensorDataWithUniqueGatewayNumbers = (
    sensorDataResponse: SensorDataResponse, last: number = 3,
) => {
    if (!sensorDataResponse || !sensorDataResponse.data || sensorDataResponse.data.length === 0) return [];
    const gatewayNumberIndex = sensorDataResponse.dataTypes.indexOf('GATEWAY_NUMBER');
    const isHistoricalIndex = sensorDataResponse.dataTypes.indexOf('IS_HISTORICAL');
    const historicalSensorData = sensorDataResponse.data.filter(({ d }) => !d[isHistoricalIndex]);

    historicalSensorData.reverse();
    if (gatewayNumberIndex === -1) return [];

    const uniqueSensorData:{
        sensorData: SensorDataItem,
        gatewayNumber: string,
    }[] = [];

    for (let i = 0; i <= historicalSensorData.length - 1; i++) {
        const gatewayNumber = historicalSensorData[i].d[gatewayNumberIndex];
        const nextGatewayNumber = historicalSensorData[i + 1]?.d[gatewayNumberIndex];

        if (i === historicalSensorData.length - 1
            || (uniqueSensorData?.at(-1)?.gatewayNumber !== gatewayNumber
            && gatewayNumber !== nextGatewayNumber)) {
            uniqueSensorData.push({
                sensorData: historicalSensorData[i],
                gatewayNumber,
            });

            if (uniqueSensorData.length === last) break;
        }
    }

    return uniqueSensorData;
};

export const extractLatestSensorDataWithUniqueGatewayNumbersHistorical = (
    sensorDataResponse: SensorDataResponse,
) => {
    if (!sensorDataResponse || !sensorDataResponse.data || sensorDataResponse.data.length === 0) return [];
    const gatewayNumberIndex = sensorDataResponse.dataTypes.indexOf('GATEWAY_NUMBER');
    const isHistoricalIndex = sensorDataResponse.dataTypes.indexOf('IS_HISTORICAL');
    const historicalSensorData = [...sensorDataResponse.data];

    historicalSensorData.reverse();
    if (gatewayNumberIndex === -1) return [];

    const uniqueSensorData:{
        sensorData: SensorDataItem,
        gatewayNumber: string,
        isHistorical?: boolean,
    }[] = [];

    for (let i = 0; i <= historicalSensorData.length - 1; i++) {
        const gatewayNumber = historicalSensorData[i].d[gatewayNumberIndex];
        const isHistorical = historicalSensorData[i].d[isHistoricalIndex];

        const newEntry = {
            sensorData: historicalSensorData[i],
            gatewayNumber,
            isHistorical: historicalSensorData[i].d[isHistoricalIndex],
        };

        const lastIndUniqueSensorData = uniqueSensorData?.length - 1;

        if (i === 0) {
            uniqueSensorData.push(newEntry);
        } else if (uniqueSensorData[lastIndUniqueSensorData]?.gatewayNumber === gatewayNumber
            && uniqueSensorData[lastIndUniqueSensorData]?.isHistorical === isHistorical) {
            uniqueSensorData[lastIndUniqueSensorData] = newEntry;
        } else {
            uniqueSensorData.push(newEntry);
        }
    }

    return uniqueSensorData;
};

export const extractLocationRemarks = (assets: ExtractedAssetData[]) => {
    if (!assets || assets.length === 0) return [];

    return Array.from(new Set(assets.map(it => it.area)));
};
const useGetGatewaysByGatewayNumbers = ({
    query = {},
    enabled = true,
}: Props) => {
    const {
        GetAll: getGateways,
    } = useSecureBackendEndpoints('gateways/search').requests;

    const getGatewaysByQuery = useCallback(() => {
        if (!query || Object.keys(query).length === 0 || Object.values(query).some(it => !it)) return () => {};
        return getGateways({
            page: 0,
            pageSize: 1000,
            ...query,
        }, false);
    }, [query]);

    const {
        data: gateways = [], status, isLoading, error,
    }: {
        data: GatewayInfoDTO[],
        isLoading: boolean,
        error: unknown,
        status:'idle' | 'loading' | 'error' | 'success'
    } = useCachedQueryRequest({
        key: getQueriesUnwrapped(query),
        request: getGatewaysByQuery,
        onCompleted: it => it?.data?.resultList || [],
        options: {
            enabled,
        },
    });

    return {
        gateways, status, isLoading, error,
    };
};

export default useGetGatewaysByGatewayNumbers;
