import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import useAvailableHeight from 'hooks/useAvailableHeight';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetAirports, { getAirportsInPassedBoundsZone } from 'hooks/useGetAirports';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Geofence } from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard';
import AssetMarker from 'TrackAndTrace/Assets/components/AssetMarker';
import AssetTooltip from 'TrackAndTrace/Assets/components/AssetTooltip';
import LegendPanel from 'TrackAndTrace/Assets/components/LegendPanel';
import AirportMarker from 'TrackAndTrace/commonComponents/AirportMarker';
import TooltipPolygonMarker from 'TrackAndTrace/commonComponents/TooltipPolygonMarker';
import AirportInfoTooltip from 'TrackAndTrace/Tooltips/AirportInfoTooltip';
import { ActiveAirportInfo } from 'TrackAndTrace/Tooltips/dataTypes';
import useSupercluster from 'use-supercluster';

import { Feature } from 'geojson';
import BaseMap from '../../commonComponents/BaseMap';
import CustomPolygon from '../components/CustomPolygon';

import { ActiveAsset, ExtractedAssetData } from '../lib';
import { isWithinBounds } from '../../lib';

type Props = {
    showAirportsInfo: boolean,
    showLastConnection: boolean,
    assets: ExtractedAssetData[],
    geofences: Geofence[],
    zoom: number,
    setZoom: (zoom: number) => void,
}

const MapView = ({
    showLastConnection,
    showAirportsInfo,
    assets,
    geofences = [],
    zoom,
    setZoom,
}: Props) => {
    const { t } = useCustomTranslation();
    const { enableTooltips } = useContext(QuickHelpContext);
    const [gMap, setGMap] = useState(null);
    const [bounds, setBounds] = useState<number[]>([]);
    const [activeAsset, setActiveAsset] = useState<ActiveAsset>({
        assetNumber: null,
        timestamp: Date.now(),
    });

    const availableSpace = useAvailableHeight();
    const [mapContainerRef, setMapContainerRef] = useState(null);
    const [activeAirportInfo, setActiveAirportInfo] = useState<ActiveAirportInfo>({
        code: null,
        timestamp: Date.now(),
    });
    const [hoveredGroupGeoKey, setHoveredGroupGeoKey] = useState<string>(null);

    const airports = getAirportsInPassedBoundsZone(useGetAirports(), bounds);

    const closeActiveTooltip = useCallback(() => {
        const delay = 700;
        const now = Date.now();

        if (activeAsset.assetNumber !== null && activeAsset.timestamp + delay < now) {
            setActiveAsset({
                assetNumber: null,
                timestamp: now,
            });
        }
        if (activeAirportInfo.code !== null && activeAirportInfo.timestamp + delay < now) {
            setActiveAirportInfo({
                code: null,
                timestamp: now,
            });
        }
    }, [activeAsset, activeAirportInfo]);

    const cancelAirportInfoTooltipClosing = useCallback(() => {
        setActiveAirportInfo(prev => ({
            code: prev.code,
            timestamp: Date.now(),
        }));
    }, []);

    const showedAirportInfo = useMemo(() => {
        if (airports.length === 0 || activeAirportInfo === null || activeAirportInfo.code === null) {
            return null;
        }

        const airport = airports.find(item => item.code === activeAirportInfo.code) || null;

        if (airport) {
            return airport;
        }
        return null;
    }, [activeAirportInfo, airports]);

    const assetsWithGeolocation = useMemo(() => assets
        .filter(({ lastMeasuredLatitude }) => lastMeasuredLatitude), [assets]);
    const {
        clusters,
        supercluster,
    } = useSupercluster({
        points: assetsWithGeolocation.map(asset => ({
            type: 'Feature',
            properties: {
                cluster: false,
                category: 'assets',
                assetId: asset.id,
            },
            geometry: {
                type: 'Point',
                coordinates: [asset.lastMeasuredLongitude, asset.lastMeasuredLatitude],
            },
        })),
        bounds: bounds as [number, number, number, number],
        zoom,
        options: {
            radius: 200,
            maxZoom: Math.max(Math.round(zoom), 1),
        },
    });

    const withoutGeolocationCount = useMemo(() => assets
        .filter(({ lastMeasuredLatitude }) => !lastMeasuredLatitude).length, [assets]);

    const filteredGeofences = useMemo(() => {
        if (zoom < 8) return [];

        const expansionInKm = 20;
        const latitudeAdjust = expansionInKm / 111;
        const midLatitude = (bounds[1] + bounds[3]) / 2;
        const longitudeAdjust = expansionInKm / (111 * Math.cos((midLatitude * Math.PI) / 180));

        const expandedBounds = {
            sw: { lat: bounds[1] - latitudeAdjust, lng: bounds[0] - longitudeAdjust },
            ne: { lat: bounds[3] + latitudeAdjust, lng: bounds[2] + longitudeAdjust },
        };

        return geofences.filter(geofence => {
            return geofence.coordinates.some(point => isWithinBounds(point, expandedBounds));
        });
    }, [bounds, geofences, zoom]);

    return (
        <div>
            <QuickHelpTooltip
                tooltipInfo={{
                    order: 5,
                    text: t('QUICK_HELP.TRACK_AND_TRACE.PACKAGING_MAP'),
                    backgroundOpacity: 0.2,
                    childOffsetPercent: [50, 50],
                    customRectSize: [60, 40],
                    offsetPx: [60, 40],
                    explicitChildRef: mapContainerRef,
                    uid: 'assetsMapTooltip',
                }}
            >
                <BaseMap
                    onContainerRefLoad={(ref) => {
                        setMapContainerRef(ref);
                    }}
                    mapContainerStyle={{
                        width: '100%',
                        height: `calc(${availableSpace} - 60px)`,
                    }}
                    gMap={gMap}
                    setGMap={setGMap}
                    setBounds={setBounds}
                    setZoom={setZoom}
                    onClick={closeActiveTooltip}
                >
                    {
                        enableTooltips && (
                            <TooltipPolygonMarker itemType="Asset" />
                        )
                    }
                    {
                        clusters.map((cluster) => {
                            let child: Feature[];

                            try {
                                child = cluster.id ? supercluster
                                    .getChildren(cluster.id as number) : [cluster];
                            } catch (error) {
                                child = [cluster];
                            }
                            const assetIds: number[] = child.map(it => it?.properties?.assetId);
                            const grouped: ExtractedAssetData[] = assetsWithGeolocation
                                .filter(asset => assetIds.includes(asset.id));

                            const geokey: string = (cluster?.id || grouped[0]?.id || '-').toString();

                            return (
                                <>
                                    {
                                        hoveredGroupGeoKey === geokey && (
                                            <AssetTooltip
                                                key={`${geokey}_tooltip`}
                                                assetGroup={grouped}
                                                position={{
                                                    lng: cluster.geometry.coordinates[0],
                                                    lat: cluster.geometry.coordinates[1],
                                                }}
                                                onMouseLeave={() => setHoveredGroupGeoKey(null)}
                                                zoom={zoom}
                                            />
                                        )
                                    }

                                    <AssetMarker
                                        key={geokey}
                                        assetGroup={grouped}
                                        position={{
                                            lng: cluster.geometry.coordinates[0],
                                            lat: cluster.geometry.coordinates[1],
                                        }}
                                        showLastConnection={showLastConnection}
                                        onHover={() => setHoveredGroupGeoKey(geokey)}
                                        isSelected={hoveredGroupGeoKey === geokey}
                                    />
                                </>
                            );
                        })
                    }

                    {
                        filteredGeofences.map(({ name, coordinates, type }) => {
                            const latLngCoordinates = coordinates
                                .map(({ latitude: lat, longitude: lng }) => ({ lat, lng }));

                            const geokey = `geofence-${
                                coordinates.map(({
                                    latitude, longitude,
                                }) => `${latitude}-${longitude}`).join('-')}`;

                            return (
                                <CustomPolygon
                                    map={gMap}
                                    isNameVisible={zoom > 15}
                                    systemGeofence={type === 'AIRPORT'}
                                    key={geokey}
                                    name={name}
                                    paths={latLngCoordinates}
                                    position={latLngCoordinates[0]}
                                />
                            );
                        })
                    }
                    {
                        showAirportsInfo && airports.map(airport => {
                            const handleClickAirportsInfo = () => {
                                setActiveAirportInfo(prev => ({
                                    code: prev.code === airport.code
                                        ? null
                                        : airport.code,
                                    timestamp: Date.now(),
                                }));
                            };

                            return (
                                <AirportMarker
                                    onClick={handleClickAirportsInfo}
                                    isOpen={activeAirportInfo.code === airport.code}
                                    airportInfo={airport}
                                    key={airport.code}
                                />
                            );
                        })
                    }
                    {
                        showedAirportInfo !== null && (
                            <AirportInfoTooltip
                                airportInfo={showedAirportInfo}
                                cancelTooltipClosing={cancelAirportInfoTooltipClosing}
                            />
                        )
                    }
                    <LegendPanel withoutGeolocationCount={withoutGeolocationCount} showWithoutGeolocation={false} />
                </BaseMap>
            </QuickHelpTooltip>
        </div>
    );
};

export default MapView;
