import DataPaginationContext from 'Contexts/DataPaginationContext';
import { FacetDTO } from 'dataTypes/SecureBackend/apiResponse';
import useAvailableHeight from 'hooks/useAvailableHeight';
import useClasses from 'hooks/useClasses';
import useGetCommonData from 'hooks/useGetCommonData';
import React, {
    useCallback,
    useEffect, useMemo,
    useState,
} from 'react';
import Table from 'shared-components/Table';
import { PageWithFilter } from 'Layout';
import TitleWithMapTableSwitch from 'shared-components/TitleWithMapTableSwitch';
import StandalonePagination from 'shared-components/StandalonePagination';
import { getOptionsFromFacets } from 'TrackAndTrace/Assets/lib';
import ShipmentFilterPanel from 'TrackAndTrace/GenericShipments/components/ShipmentFilters';
import {
    GenericShipmentData,
    GenericShipmentDTO,
    getShipmentData,
    initialShipmentFilterOptions,
} from 'TrackAndTrace/GenericShipments/lib';
import { CustomSort, trimQuery } from 'TrackAndTrace/lib';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetViewTypeFromQuery from 'TrackAndTrace/localHooks/useGetViewTypeFromQuery';
import useFilter, { PAGES } from 'Contexts/useFilter';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import tableColumns from './tableColumns';
import { styles } from './GenericShipments.style';
import MapView from './MapView';
import { MAP_CONFIG } from '../../shared-components/constants';

const PAGE_SIZE = 10;

const GenericShipments = () => {
    const view = useGetViewTypeFromQuery();
    const { t } = useCustomTranslation();
    const classes = useClasses(styles);
    const { getFilter, setFilter } = useFilter(PAGES.GENERIC_SHIPMENTS);
    const filter = getFilter();
    const hasAccess = useHasAccess();
    const [zoom, setZoom] = useState(MAP_CONFIG.zoom);

    const [page, setPage] = useState(filter?.page || 1);
    const [totalElements, setTotalElements] = useState(filter?.totalElements || 0);
    const [query, setQuery] = useState(filter?.query || '');
    const [shipments, setShipments] = useState<GenericShipmentData[]>([]);
    const [highlightedText, setHighlightedText] = useState('');
    const [showAirportsInfo, setShowAirportsInfo] = useState(filter?.showAirportsInfo || false);

    const {
        data: rawInitialFacets,
        status: rawInitialFacetsStatus,
    } = useGetCommonData<FacetDTO[]>('v2/shipments/search/facets',
        {
            cache: true,
            query: {
                initial: true,
            },
        });
    const availableHeight = useAvailableHeight();
    const [sort, setSort] = useState<CustomSort>(filter?.sort || null);

    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);

    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>(filter?.filterOptions || initialShipmentFilterOptions);

    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>('v2/shipments/search/facets',
        {
            cache: true,
            query: {
                ...(trimQuery(filterOptions, query, rawInitialFacets)),
            },
            queryWrap: false,
        });

    useEffect(() => {
        if (view === 'map') {
            setPage(1);
        }
        setZoom(MAP_CONFIG.zoom);
    }, [view]);

    const {
        data: rawShipments, status: shipmentsStatus,
    } = useGetCommonData<GenericShipmentDTO>('v2/shipments/search', {
        cache: true,
        query: {
            page: page - 1,
            pageSize: PAGE_SIZE,
            ...(trimQuery(filterOptions, query, rawInitialFacets, sort, rawFacets)),
        },
        enabled: facetsStatus === 'SUCCESS',
        queryWrap: false,
    });

    useEffect(() => {
        if (shipmentsStatus === 'SUCCESS' && rawShipments?.resultList) {
            const allShipments = getShipmentData(rawShipments?.resultList);

            setTotalElements(rawShipments.totalElements);
            setShipments(allShipments);
        }
    }, [shipmentsStatus, rawShipments]);

    useEffect(() => {
        if (Object.values(filterOptions).some(options => options.length !== 0)) return;

        if (rawInitialFacets && rawInitialFacetsStatus === 'SUCCESS') {
            setFilterOptions({
                energyLevelStatuses: getOptionsFromFacets(rawInitialFacets, 'energyLevelStatuses'),
                packagingTypeCodes: getOptionsFromFacets(rawInitialFacets, 'packagingTypeCodes'),
                temperatureStatuses: getOptionsFromFacets(rawInitialFacets, 'temperatureStatuses'),
                destinationAirports: getOptionsFromFacets(rawInitialFacets, 'destinationAirports'),
                originAirports: getOptionsFromFacets(rawInitialFacets, 'originAirports'),
                statuses: getOptionsFromFacets(rawInitialFacets, 'statuses'),
                temperatureRanges: getOptionsFromFacets(rawInitialFacets, 'temperatureRanges'),
            });
        }
    }, [rawInitialFacets, rawInitialFacetsStatus]);

    const minMaxTempArray = useMemo<[number, number]>(() => {
        const allTemps = shipments.map(({ packagings }) => packagings.map((p) => [
            p?.temperatureCheckResult?.temperatureMax,
            p?.temperatureCheckResult?.temperatureMin,
        ]))
            .flat().flat()
            .filter(it => it)
            .sort((a, b) => a - b);

        return [allTemps[0], allTemps[allTemps.length - 1]];
    }, [shipments]);

    const hasAccessToEnergy = useMemo(() => hasAccess(userRoles.INTELLIGENT_MONITORING), [userRoles]);

    const columns = useMemo(() => tableColumns(t, minMaxTempArray, hasAccessToEnergy), [t, minMaxTempArray]);

    useEffect(() => {
        if ((page - 1) * PAGE_SIZE >= totalElements && page !== 1) setPage(Math.ceil(totalElements / PAGE_SIZE));
    }, [page, totalElements]);

    useEffect(() => {
        setHighlightedText(query);
    }, [query]);

    useEffect(() => {
        setFilter({
            page,
            totalElements,
            query,
            filterOptions,
            sort,
            showAirportsInfo,
        });
    }, [page, totalElements, query, filterOptions, sort, showAirportsInfo]);

    return (
        <DataPaginationContext.Provider value={{
            page,
            setPage,
            perPage: PAGE_SIZE,
            totalElements,
            paginationLoading: shipmentsStatus === 'PENDING',
        }}
        >
            <PageWithFilter>
                <ShipmentFilterPanel
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    facets={rawFacets}
                    initialFacets={rawInitialFacets}
                    countsLoading={facetsStatus === 'PENDING'}
                    query={query}
                    setQuery={setQuery}
                    showAirportsInfo={showAirportsInfo}
                    setShowAirportsInfo={setShowAirportsInfo}
                    isTableView={view === 'table'}
                />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    overflow: 'auto',
                    maxHeight: availableHeight,
                }}
                >
                    <TitleWithMapTableSwitch
                        isTableView={view === 'table'}
                        pathname="/track-and-trace/generic-shipments"
                    />
                    {
                        view === 'table'
                            ? (
                                <Table
                                    columns={columns}
                                    data={shipments}
                                    title={t('MENU_ITEMS.TRACK_AND_TRACE_SHIPMENTS')}
                                    rowLinkTemplate="/track-and-trace/generic-shipments/:id"
                                    maskForHighlight={highlightedText}
                                    classNames={{
                                        tableContainerClassName: classes.filteredTable,
                                    }}
                                    currentSort={sort}
                                    onSort={updateSort}
                                />
                            )
                            : (
                                <MapView
                                    error={null}
                                    showAirportsInfo={showAirportsInfo}
                                    shipments={shipments}
                                    zoom={zoom}
                                    setZoom={setZoom}
                                />
                            )
                    }
                    <div style={{ flex: 1 }} />
                    {
                        view === 'table' && (
                            <StandalonePagination
                                detached
                                entity={t('MENU_ITEMS.TRACK_AND_TRACE_SHIPMENTS')}
                            />
                        )
                    }
                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>
    );
};

export default GenericShipments;
