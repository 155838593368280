import React, { useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import useHasAccess from 'hooks/useHasAccess';

import AdministrationRouter from 'Administration/AdministrationRouter';
import Dashboard from 'Dashboard';
import Notifications from 'Notifications';
import ProductReleaseRouter from 'ProductRelease/ProductReleaseRouter';
import ServiceProvidersRouter from 'ServiceProviders/ServiceProvidersRouter';
import MyProfile from 'MyProfile/MyProfile';
import NoAccessRights from 'shared-components/NoAccessRights';
import MyCompanyRouter from 'MyCompany/MyCompanyRouter';

import SensorDataCommonRouter from 'SensorDataCommon/SensorDataRouter';
import getTrackAndTraceRouter from 'TrackAndTrace/TrackAndTraceRouter';
import getInsightsRouter from 'Insights/InsightsRouter';
import LaneManagementRouter from 'LaneManagement/LaneManagementRouter';
import NotificationTemplateTester from 'Notifications/NotificationTemplateTester';

const Root = () => {
    const hasAccess = useHasAccess();

    const routerCollection = useMemo(() => [
        ...AdministrationRouter,
        ...LaneManagementRouter,
        ...MyCompanyRouter,
        ...ServiceProvidersRouter,
        ...SensorDataCommonRouter,
        ...getTrackAndTraceRouter(hasAccess),
        ...getInsightsRouter(hasAccess),
        ...ProductReleaseRouter,
    ], [hasAccess]);

    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
            {routerCollection.map((item, index) => (
                <Route
                    key={index}
                    path={typeof item.path === 'string' ? item.path : item.path.join('/')}
                    element={
                        item.sufficientRoles
                            ? (item.sufficientRoles || []).some(role => hasAccess(role))
                                ? item.element
                                : <NoAccessRights />
                            : (item.necessaryRoles || []).every(role => hasAccess(role))
                                ? item.element
                                : <NoAccessRights />
                    }
                />
            ))}
            <Route path="/invoices" element={<div>Invoices</div>} />
            <Route path="/documents" element={<div>Documents</div>} />
            <Route
                path="/notifications/test-template"
                element={process.env.REACT_APP_ENVIRONMENT === 'dev'
                    ? <NotificationTemplateTester /> : <Navigate to="/" />}
            />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/my-profile" element={<MyProfile />} />
        </Routes>
    );
};

export default Root;
