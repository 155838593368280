import React, { createContext, CSSProperties, Dispatch, SetStateAction, useMemo, useState, useCallback } from 'react';
import { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import QuickHelpElement from 'Contexts/QuickHelpContext/QuickHelpElement';
import { SvgWithCutouts } from './generateSvgWithCutouts';
import { QuickHelpPage } from './QuickHelpPage';

export interface QuickHelpContextInterface {
    enableTooltips: boolean,
    setEnableTooltips: Dispatch<SetStateAction<boolean>>,
    enableGuide: boolean,
    setEnableGuide: Dispatch<SetStateAction<boolean>>,
    hasUserGuide: boolean,
    setHasUserGuide: Dispatch<SetStateAction<boolean>>,
    quickHelpTooltipsBag: string[],
    setTooltipInfos: Dispatch<SetStateAction<{ [key: string]: QuickHelpTooltipInfo; }>>,
}

export const QuickHelpContext = createContext<QuickHelpContextInterface>({
    enableTooltips: false,
    setEnableTooltips: () => false,
    enableGuide: false,
    setEnableGuide: () => false,
    hasUserGuide: false,
    setHasUserGuide: () => false,
    quickHelpTooltipsBag: [],
    setTooltipInfos: () => false,
});

const backStyle: CSSProperties = {
    transition: 'opacity 200ms ease',
    zIndex: 10000,
    background: 'transparent',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
};

const QuickHelpContextProvider = ({ children }) => {
    const [enableTooltips, setEnableTooltips] = useState<boolean>(false);
    const [enableGuide, setEnableGuide] = useState<boolean>(false);
    const [hasUserGuide, setHasUserGuide] = useState<boolean>(false);
    const [tooltipInfos, setTooltipInfos] = useState<{
        [key:string]: QuickHelpTooltipInfo
    }>({ });
    const quickHelpTooltipsBag = useMemo<string[]>(() => Object.keys(tooltipInfos || {}), [tooltipInfos]);

    const quideBackStyle = useMemo<CSSProperties>(() => ({
        pointerEvents: enableTooltips ? 'all' : 'none',
        opacity: enableTooltips ? 0.3 : 0,
        cursor: enableTooltips ? 'pointer' : 'none',
        ...backStyle,
    }), [enableTooltips]);
    const knowledgeBaseBackStyle = useMemo<CSSProperties>(() => ({
        pointerEvents: enableGuide ? 'all' : 'none',
        opacity: enableGuide ? 0.3 : 0,
        cursor: enableGuide ? 'pointer' : 'none',
        ...backStyle,
        background: 'rgba(0, 0, 0, 0.6)',
    }), [enableGuide]);

    const handleBackgroundClick = useCallback(() => {
        setEnableTooltips(false);
        setEnableGuide(false);
    }, []);
    const cutouts = useMemo(() => (
        enableGuide ? [] : Object.values(tooltipInfos || {})
    ), [tooltipInfos, enableGuide]);

    return (
        <QuickHelpContext.Provider value={{
            enableTooltips,
            setEnableTooltips,
            enableGuide,
            setEnableGuide,
            hasUserGuide,
            setHasUserGuide,
            quickHelpTooltipsBag,
            setTooltipInfos,
        }}
        >
            <div
                style={knowledgeBaseBackStyle}
                onClick={handleBackgroundClick}
            />
            <SvgWithCutouts
                tooltipInfos={cutouts}
                style={quideBackStyle}
                onClick={handleBackgroundClick}
            />
            <QuickHelpPage />
            { children }
            { enableTooltips && Object.values(tooltipInfos || {})
                .map(tooltipInfo => <QuickHelpElement tooltipInfo={tooltipInfo} />)}
        </QuickHelpContext.Provider>
    );
};

export default QuickHelpContextProvider;
