import moment from 'moment';
import icons from 'shared-components/icons';
import { ExtractedLoggerData } from 'TrackAndTrace/Loggers/lib';

import { ExtractedAssetData } from 'TrackAndTrace/Assets/lib';
import {
    ORDER_STEP_TYPE,
    TIME_IN_MS,
} from './constants';
import { ShortContainerInfo } from './dataTypes';

export const getUtcTimeStamp = (utcTimeStamp: string, withOffset = true, inLocalTimeZone = false): number => {
    if (utcTimeStamp.includes('T')) {
        return new Date(utcTimeStamp).getTime();
    }

    try {
        const [date, time, timeZone = null] = utcTimeStamp.split(' ');
        const dateArr = date.split('.');
        const isoDateString = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}T${time}`;
        const timeStampMs = new Date(isoDateString).getTime();

        if (withOffset && timeZone) {
            const offset = (Number(timeZone) / 100) * TIME_IN_MS.hour;

            if (!Number.isNaN(offset)) {
                const localOffset = inLocalTimeZone
                    ? 0
                    : new Date().getTimezoneOffset() * TIME_IN_MS.minute;

                return timeStampMs - offset - localOffset;
            }
        }

        return timeStampMs;
    } catch (e) {
        return null;
    }
};

export const convertUtcTimeStampToIsoString = (
    utcTimeStamp: string, withOffset = true, inLocalTimeZone = false,
): string => {
    // Temporary solution to convert date from API like a '16.09.2021 12:44:02'
    // to IsoString like a '2021-09-16T12:44:02.000Z'
    if (utcTimeStamp.includes('T')) {
        return utcTimeStamp;
    }

    try {
        const timeStamp = getUtcTimeStamp(utcTimeStamp, withOffset, inLocalTimeZone);

        return new Date(timeStamp).toISOString();
    } catch (e) {
        return '';
    }
};

export const normalizedDate = (date: Date | number | string | moment.Moment,
    type: 'from' | 'to' | 'now' = 'from'): string => {
    const normalizedDate = date ? moment(date) : moment();

    let time = '';

    switch (type) {
    case 'from':
        time = '00:00';
        break;
    case 'to':
        time = '23:59';
        break;
    case 'now':
        time = moment().format('HH:mm');
        break;
    default:
        time = '';
    }
    return `${normalizedDate.format('DD.MM.YYYY')} ${time}`;
};

export const getPolylinesFromMock = (sensorData = []) => {
    const path = sensorData.map((step) => (
        {
            lat: step.d[0],
            lng: step.d[1],
        }
    ));

    return (
        [{
            path,
            stepStatus: null,
        }]
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const handleLoggerRequest = (request, setter, bool, usage, includeFilters = {}) => {
    if (!bool) {
        setter([]);
    } else {
        (async () => {
            const result = await request({ rows: 500 });

            setter((result?.data?.items || []).map(it => ({ ...it, usage })));
        }
        )();
    }
};

type ObjectWithTArray<T = object> = {
    [key:string]: T[]
}

export const joinLoggers: <T>(loggers: (ExtractedAssetData | ExtractedLoggerData)[])
    => ObjectWithTArray<T> = (loggers) => {
        return loggers.reduce((acc, marker) => {
            const key = `${marker.lastMeasuredLatitude}_${marker.lastMeasuredLongitude}`;

            acc[key] = [...(acc[key] || []), marker];
            return acc;
        }, {});
    };

export const lastConnectedData = (dateUTC) => {
    if (dateUTC === 'Various') {
        return {
            clazz: 'variousBatch',
            text: 'Various',
        };
    }

    const duration = moment
        .duration(moment()
            .diff(moment(dateUTC * 1000)));
    const minutes = Math.ceil(Math.abs(duration.asMinutes()));
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    let className;

    if (minutes < 60) {
        className = 'green';
    } else if (minutes >= 60 && minutes < 60 * 24) {
        className = 'yellow';
    } else {
        className = 'red';
    }

    const timeDiff = (days ? `${days}d` : '')
        || (hours ? `${hours}h` : '')
        || (minutes ? `${minutes}m` : '');

    return {
        clazz: `${className}Batch`,
        text: timeDiff,
    };
};

type PathInfo = {
    param?: null,
    query?: {
        [key: string]: string
    },
}

export const parseParamAndQueries = (historyLocation) => {
    const pathInfo: PathInfo = {};

    const [param] = historyLocation.pathname.split('/').slice(-1);

    if (param !== ' ') {
        try {
            pathInfo.param = param;

            if (historyLocation.search) {
                pathInfo.query = historyLocation.search.replace('?', '').split('&').reduce((obj, query) => {
                    const [key, value] = query.split('=');
                    const queryObject = { ...obj };

                    queryObject[key] = value;

                    return queryObject;
                }, {});
            }
        } catch (error) {
            pathInfo.param = null;
            pathInfo.query = {};
            global.console.log(error);
        }
    }

    return pathInfo;
};

export const range = (start: number, stop: number, step: number = 1): number[] => {
    return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
};

export const invertColor = (hex, theme) => {
    if (theme === 'default') return hex;

    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16);
    const g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16);
    const b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);

    return `#${r.padStart(2, '0').slice(-2)}${g.padStart(2, '0').slice(-2)}${b.padStart(2, '0').slice(-2)}`;
};

export const fetchProcessedContainerData = (rawContainerData): ShortContainerInfo => {
    const { loggerContainerInformation = [] } = rawContainerData;
    const allowedDataTypes = ['DOOR', 'GEOLOCATION', 'TEMPERATURE'];

    const availableRequestOptions = loggerContainerInformation.reduce((data, item) => {
        const {
            logger = {},
            loggerPosition = null,
        } = item;
        const {
            loggerTypeCode = null,
            loggerTypeDetails = {},
        } = logger;
        const { dataTypes: rawDataTypes = [] } = loggerTypeDetails;
        const {
            loggers: prevLoggers,
            dataTypes: prevDataTypes,
            positions: prevPositions,
        } = data;

        const loggers = !loggerTypeCode || prevLoggers.includes(loggerTypeCode)
            ? prevLoggers
            : [...prevLoggers, loggerTypeCode];

        const positions = !loggerPosition
                        || loggerPosition === 'NOT_APPLICABLE'
                        || prevPositions.includes(loggerPosition)
            ? prevPositions
            : [...prevPositions, loggerPosition];

        const hasGeolocation = rawDataTypes.includes('LOCATION_LATITUDE')
            || rawDataTypes.includes('LOCATION_LONGITUDE');

        const updatedRawDataTypes = hasGeolocation
            ? rawDataTypes.filter((type) => !type.includes('LOCATION_LATITUDE') || !type.includes('LOCATION_LONGITUDE'))
                .concat(['GEOLOCATION'])
            : rawDataTypes;

        const uniqueNewDataTypes = updatedRawDataTypes.filter((type) => (
            !prevDataTypes.includes(type) && allowedDataTypes.includes(type)));

        const dataTypes = uniqueNewDataTypes.length === 0
            ? prevDataTypes
            : prevDataTypes.concat(uniqueNewDataTypes);

        return {
            loggers,
            dataTypes,
            positions,
        };
    }, {
        loggers: [],
        dataTypes: [],
        positions: [],
    });

    const loggerInfo = loggerContainerInformation.reduce((data, item) => {
        const {
            logger = {},
            exchangeTimestamp = null,
            removedOn = null,
        } = item;
        const { loggerTypeCode = null, loggerTypeDetails = {} } = logger;
        const { dataTypes: rawDataTypes = [] } = loggerTypeDetails;

        if (loggerTypeCode && rawDataTypes.length !== 0) {
            const hasGeolocation = rawDataTypes.includes('LOCATION_LATITUDE')
                || rawDataTypes.includes('LOCATION_LONGITUDE');

            const dataTypes = hasGeolocation
                ? rawDataTypes.filter((type) => (
                    !type.includes('LOCATION_LATITUDE') || !type.includes('LOCATION_LONGITUDE')
                )).concat(['GEOLOCATION'])
                : rawDataTypes;

            return {
                ...data,
                [loggerTypeCode]: {
                    dataTypes,
                    exchangeTimestamp,
                    removedOn,
                },
            };
        }
        return data;
    }, {});

    return {
        defaultLoggerType: rawContainerData.defaultLoggerType,
        lastMeasuredTempInternal: rawContainerData.lastMeasuredTempInternalVirtual,
        lastMeasuredOnUtcInternal: rawContainerData.lastMeasuredOnUtcTempInternalVirtual,
        model: rawContainerData.containerModelExternal,
        picture:
            rawContainerData.containerType?.containerPicture?.attachmentUrl
                ? rawContainerData.containerType.containerPicture.attachmentUrl
                : icons.default_packaging_icon,
        serialNumber: rawContainerData.serialNumber,
        tempRange: rawContainerData.tempRange,
        availableRequestOptions: {
            ...availableRequestOptions,
            positions: [
                'INTERNAL',
                'AMBIENT',
                'TEMPERATURE_PREDICTED',
            ],
        },
        loggerInfo,
    };
};

export const getPolylineType = (type: string) => {
    if (type === ORDER_STEP_TYPE.CLOSED) {
        return {
            geodesic: true,
            strokeOpacity: 1.0,
            strokeWeight: 3,
        };
    }

    return {
        geodesic: true,
        strokeOpacity: 0,
        icons: [{
            icon: {
                path: 'M 0 -1 L 0 0 L 1 0 L 1 -1',
                strokeOpacity: 1,
                scale: 3,
            },
            offset: '0',
            repeat: '12px',
        }],
    };
};

export const nonNullKey = (object:Object) => {
    if (!object) return null;
    return Object.keys(object).find(key => object[key]);
};
export const nonNullObject = (object:Object) => {
    if (!object) return null;

    return Object.values(object).find(value => value);
};

export const downloadFileFromString = (string: string, filename: string, type = 'text/csv;charset=utf-8,%EF%BB%BF') => {
    const blob = new Blob([`\ufeff${ string}`], { type });
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
export const downloadBase64File = (base64: string, filename: string) => {
    const link = document.createElement('a');

    link.href = base64;
    link.download = filename;
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
