import React from 'react';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import icons from 'shared-components/icons';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import useGetCommonData from '../../hooks/useGetCommonData';

type Props = {
    energyLevel?: number,
    threshold?: boolean,
}
const EnergyLevel = ({
    energyLevel,
    threshold = false,
}: Props) => {
    const { company } = useCurrentUserContext();
    const { t } = useCustomTranslation();
    const {
        data: batteryLevelSettings,
    } = useGetCommonData<{
        batteryLevelThreshold: number,
    }>('assets/battery-level-settings');
    const energyLevelThreshold = threshold
        ? batteryLevelSettings.batteryLevelThreshold : company.energyLevelThreshold;

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            color: energyLevel < energyLevelThreshold ? '#D44848' : 'black',
        }}
        >
            {energyLevel === null || energyLevel === undefined
                ? (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'left',
                        color: '#747474',
                        opacity: 1,
                    }}
                    >
                        {t('TEMPERATURE_STATUS.NO_DATA')}
                    </div>
                )
                : energyLevel < energyLevelThreshold
                    ? (
                        <img
                            style={{
                                marginRight: 4,
                                height: 14,
                            }}
                            key="icon_warning"
                            src={Number(energyLevel) === 0
                                ? icons.red_triangle : Number(energyLevel) < energyLevelThreshold
                                    ? icons.warning_triangle : ''}
                            alt="warning"
                        />
                    )
                    : ''}
            <div>
                {!(energyLevel === null || energyLevel === undefined)
                    && `${Number(energyLevel)}%`}
            </div>
            <br />
            <br />
        </div>
    );
};

export default EnergyLevel;
