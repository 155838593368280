import React, { MouseEventHandler } from 'react';
import LastConnect from 'shared-components/LastConnect';
import { useTheme } from '@mui/material/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import useClasses from 'hooks/useClasses';
import PolygonIcon, {
    PolygonIconType,
    PolygonIconColor,
    getPosition,
} from './PolygonIcon';
import styles from './PolygonMarker.style';

type Props = {
    count?: number,
    itemType?: string,
    lastConnected?: number,
    lastConnectedIsVarious?: boolean,
    onClick?: MouseEventHandler<HTMLDivElement>,
    className?: string,
    polygonIconClassName?: string,
    polygonIconColor?: PolygonIconColor,
    iconDiameter?: number,
    theme?: 'default' | 'light',
    filled?: boolean,
    polygonIconType?: PolygonIconType,
    fontSize?: number,
}

const PolygonMarker = ({
    count = 0,
    lastConnectedIsVarious = false,
    itemType = null,
    lastConnected = null,
    onClick = () => {},
    className = '',
    iconDiameter = 22,
    polygonIconColor = 'blue',
    polygonIconType = 'hexa',
    polygonIconClassName = '',
    theme = 'default',
    filled = false,
    fontSize = null,
}: Props) => {
    const classes = useClasses(styles);
    const muiTheme = useTheme<SkycellThemeInterface>();

    return (
        <div
            className={`${classes.polygonMarker} ${className}`}
            onClick={onClick}
            style={{
                minWidth: iconDiameter,
            }}
        >
            {
                count > 0 && (
                    <div
                        className={classes.clusterCount}
                        style={{
                            width: iconDiameter,
                            height: iconDiameter,
                            color: filled ? muiTheme.palette.common.white : (theme === 'light'
                                ? muiTheme.palette.primary.deepBlue : muiTheme.palette.common.black),
                            fontSize: fontSize || 'unset',
                        }}
                    >
                        {Math.min(count, 99)}
                    </div>
                )
            }
            <PolygonIcon
                className={polygonIconClassName}
                color={polygonIconColor}
                iconDiameter={iconDiameter}
                type={polygonIconType}
                theme={theme}
                filled={filled}
            />
            {
                lastConnected && (
                    <div
                        className={classes.badge}
                        style={getPosition(iconDiameter, 'topRight')}
                    >
                        <LastConnect
                            timestamp={lastConnected}
                            various={lastConnectedIsVarious}
                            fontSize="small"
                        />
                    </div>
                )
            }
            {
                itemType && (
                    <div
                        className={`${classes.badge} ${classes.itemTypeBadge}`}
                        style={getPosition(iconDiameter, 'bottomRight')}
                    >
                        {itemType}
                    </div>
                )
            }
        </div>
    );
};

export default PolygonMarker;
