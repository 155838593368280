import React, {
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import Switch from '@mui/material/Switch';
import { TimeRange } from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import Card from 'shared-components/Card';
import PeriodSelectorWithDatePicker from 'shared-components/PeriodSelectorWithDatePicker';
import SwitchWithLabel from 'shared-components/SwitchWithLabel';
import { userRoles } from 'hooks/useHasAccess';
import useClasses from 'hooks/useClasses';
import {
    EntitySelectorItem,
    initialEntitySelectorItem,
} from '../../dataTypes';

import EntitySelector from '../EntitySelector/EntitySelector';
import styles from './Options.style';

const AMBIENT = 'AMBIENT';
const INTERNAL = 'INTERNAL';
// const DOOR = 'DOOR';
const LOCATION = 'LOCATION';
const LOCATION_LATITUDE = 'LOCATION_LATITUDE';
const LOCATION_LONGITUDE = 'LOCATION_LONGITUDE';
const TEMPERATURE = 'TEMPERATURE';

type Props = {
    entitySelectorItem?: EntitySelectorItem,
    disabled?: boolean,
    localTimezone?: boolean,
    requestBody?: SensorDataRequestBody,
    setRequestBody?: (requestBody: SensorDataRequestBody) => void,
    setLocalTimezone?: (prev: SetStateAction<boolean>) => void,
    setShowMap?: (showMap: boolean) => void,
    timeRange?: TimeRange,
    isCurrentTime?: boolean,
    setIsCurrentTime?: (isCurrentTime?: boolean) => void,
    setEntitySelectorItem?: (entitySelectorItem: EntitySelectorItem) => void,
    shipmentsActivePackagingSerialNumber?: string,
    setTimeRange?: (timeRange: TimeRange) => void,
    // shipmentList?: EntitySelectorItem[],
    // packagingList: EntitySelectorItem[],
    loggerList?: EntitySelectorItem[],
    assetList?: EntitySelectorItem[],
}

const Options = ({
    disabled = false,
    localTimezone = false,
    requestBody = null,
    setRequestBody = null,
    setLocalTimezone = null,
    setShowMap = null,
    shipmentsActivePackagingSerialNumber = null,
    timeRange = null,
    isCurrentTime = false,
    setIsCurrentTime = null,
    entitySelectorItem = initialEntitySelectorItem,
    setEntitySelectorItem = null,
    // shipmentsActivePackagingSerialNumber = '',
    setTimeRange = null,
    // shipmentList = [],
    // packagingList = [],
    loggerList = [],
    assetList = [],
} : Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    const {
        additionalData,
        entityType,
    } = entitySelectorItem ?? {};
    const {
        lastMeasuredTemp = null,
        lastMeasuredTempAmbient = null,
        lastMeasuredTempInternal = null,
        lastMeasuredGeolocation = null,
        packagingsInThisShipment = [],
        shipmentStart = null,
        shipmentEnd = null,
        leaseStart = null,
        leaseEnd = null,
        leaseEndExpected = null,
    } = additionalData ?? {};

    const [sampleForSearch, setSampleForSearch] = useState('');
    const [shipmentsChoosed, setChooseShipments] = useState(true);
    // const [packagingsChoosed, setChoosePackagings] = useState(true);
    const [loggersChoosed, setChooseLoggers] = useState(true);
    const [assetsChoosed, setChooseAssets] = useState(true);

    const collectedItems = useMemo(() => {
        try {
            // const shipments = shipmentsChoosed && shipmentList.length ? shipmentList : [];
            // const packagings = packagingsChoosed && packagingList.length ? packagingList : [];
            const loggers = loggersChoosed && loggerList.length ? loggerList : [];
            const assets = assetsChoosed && assetList.length ? assetList : [];

            return [
                // ...shipments,
                // ...packagings,
                ...loggers,
                ...assets,
            ];
        } catch (error) {
            return [];
        }
    }, [
        // shipmentList,
        // packagingList,
        loggerList,
        assetList,
        shipmentsChoosed,
        // packagingsChoosed,
        loggersChoosed,
        assetsChoosed,
    ]);

    const filteredOptions = useMemo(() => {
        const sampleForSearchLowerCase = sampleForSearch.toLowerCase();

        return sampleForSearchLowerCase
            ? collectedItems.filter(option => (
                option.entityNumber.toLowerCase().includes(sampleForSearchLowerCase)
                || (option.entityType === 'shipment'
                    && option.additionalData.packagingsInThisShipment
                        .some(packaging => packaging.serialNumber.toLowerCase().includes(sampleForSearchLowerCase)))
                || (option.entityType === 'shipment'
                    && option.additionalData.mawbNumber !== null
                    && option.additionalData.mawbNumber.toLowerCase().includes(sampleForSearchLowerCase)
                    && option.additionalData.hawbNumber !== null
                    && option.additionalData.hawbNumber.toLowerCase().includes(sampleForSearchLowerCase))
                || (option.entityType === 'assets'
                    && option.additionalData.assetNumber.toLowerCase().includes(sampleForSearchLowerCase))
            ))
            : collectedItems.slice(0, 25);
    }, [collectedItems, sampleForSearch]);

    const showInternalTemperature = useMemo(() => {
        return entityType === 'shipment' || entityType === 'packagings';
    }, [entityType, lastMeasuredTempInternal]);

    const showAmbientTemperature = useMemo(() => {
        return entityType === 'shipment' || entityType === 'packagings';
    }, [entityType, lastMeasuredTempAmbient]);

    const showTemperature = useMemo(() => {
        return entityType === 'assets' && lastMeasuredTemp !== null;
    }, [entityType, lastMeasuredTemp]);

    /* const showDoor = useMemo(() => {
        return entityType === 'shipment' || entityType === 'packagings';
    }, [entityType]); */

    const showLocation = useMemo(() => {
        return entityType === 'assets';
    }, [entityType, lastMeasuredGeolocation]);

    const showGeolocation = useMemo(() => {
        return entityType === 'shipment'
        || ((entityType === 'packagings' || entityType === 'loggers')
            && lastMeasuredGeolocation !== null
            && lastMeasuredGeolocation.latitude && lastMeasuredGeolocation.longitude);
    }, [entityType, lastMeasuredGeolocation]);

    const disabledGeolocation = useMemo(() => {
        if (entityType === 'packagings' || entityType === 'loggers' || packagingsInThisShipment.length === 0) {
            return false;
        }
        if (shipmentsActivePackagingSerialNumber === null) {
            return true;
        }

        const { lastMeasuredGeolocation = null } = packagingsInThisShipment
            .find(item => item.serialNumber === shipmentsActivePackagingSerialNumber) || {};

        return lastMeasuredGeolocation === null;
    }, [entityType, shipmentsActivePackagingSerialNumber]);

    const geolocationTitle = useMemo(() => {
        const baseTitle = entityType === 'loggers'
            ? t('COMMON.MAP')
            : t('SENSOR_DATA.GEOLOCATION');

        const noteMark = disabledGeolocation ? '¹' : '';

        return `${baseTitle}${noteMark}`;
    }, [entityType, disabledGeolocation]);

    const [temperature, setTemperature] = useState(true);
    const [internalTemperature, setInternalTemperature] = useState(true);
    const [ambientTemperature, setAmbientTemperature] = useState(true);
    // const [door, setDoor] = useState(true);
    const [geolocation, setGeolocation] = useState(false);
    const [location, setLocation] = useState(true);
    const [firstEntry, setFirstEntry] = useState(true);

    useEffect(() => {
        if (!firstEntry) {
            setRequestBody({
                dataTypes: [
                    ...(internalTemperature || ambientTemperature || temperature
                        ? [TEMPERATURE]
                        : []),
                    // ...(door ? [DOOR] : []),
                    ...(geolocation ? [LOCATION_LATITUDE, LOCATION_LONGITUDE] : []),
                    ...(location ? [LOCATION] : []),
                ],
                loggers: [],
                positions: [
                    ...(ambientTemperature ? [AMBIENT] : []),
                    ...(internalTemperature ? [INTERNAL] : []),
                ],
            });
            setShowMap(geolocation);
        }
    }, [
        ambientTemperature,
        // door,
        geolocation,
        location,
        internalTemperature,
        temperature,
    ]);

    useEffect(() => {
        setLocalTimezone(true);
        if (firstEntry && entitySelectorItem?.entityNumber && requestBody.dataTypes.length > 0) {
            setTemperature(showTemperature || entityType === 'loggers');
            setInternalTemperature(showInternalTemperature && requestBody.positions.includes(INTERNAL));
            setAmbientTemperature(showAmbientTemperature && requestBody.positions.includes(AMBIENT));
            setGeolocation(
                requestBody.dataTypes.includes(LOCATION_LATITUDE)
                && requestBody.dataTypes.includes(LOCATION_LONGITUDE),
            );
            // setDoor(requestBody.dataTypes.includes(DOOR));
            setLocation(requestBody.dataTypes.includes(LOCATION));
            setFirstEntry(false);
        } else if (!firstEntry || requestBody.dataTypes.length === 0) {
            if (entitySelectorItem?.entityNumber) {
                if (entityType === 'loggers' || entityType === 'assets') {
                    setTemperature(showTemperature || entityType === 'loggers');
                    setInternalTemperature(showInternalTemperature);
                    setAmbientTemperature(showAmbientTemperature);
                    // setDoor(false);
                    setLocation(showLocation);
                } else {
                    setTemperature(false);
                    setInternalTemperature(true);
                    setAmbientTemperature(true);
                    // setDoor(true);
                    setLocation(false);
                }
                setGeolocation(false);
                setFirstEntry(false);
            } else {
                setTemperature(false);
                setInternalTemperature(false);
                setAmbientTemperature(false);
                setGeolocation(false);
                // setDoor(false);
                setLocation(false);
            }
        }
    }, [entitySelectorItem]);

    const handleChangeLeaseEndExpected = (event) => {
        setIsCurrentTime(event.target.checked);
    };

    const handleChangeShowInMyLocalTime = useCallback(() => setLocalTimezone(prev => !prev), []);
    const handleChangeTemperature = useCallback(() => setTemperature(prev => !prev), []);
    const handleChangeInternalTemperature = useCallback(() => setInternalTemperature(prev => !prev), []);
    const handleChangeAmbientTemperature = useCallback(() => setAmbientTemperature(prev => !prev), []);
    const handleChangeGeolocation = useCallback(() => setGeolocation(prev => !prev), []);
    const handleChangeLocation = useCallback(() => setLocation(prev => !prev), []);
    // const handleChangeDoor = useCallback(() => setDoor(prev => !prev), []);

    return (
        <Card title={t('SENSOR_DATA.OPTIONS')}>
            <EntitySelector
                assetsChoosed={assetsChoosed}
                assetsCount={assetList.length}
                entitySelectorItem={entitySelectorItem}
                filteredOptions={filteredOptions}
                sampleForSearch={sampleForSearch}
                setSampleForSearch={setSampleForSearch}
                setEntitySelectorItem={setEntitySelectorItem}
                // shipmentsActivePackagingSerialNumber={shipmentsActivePackagingSerialNumber}
                // shipmentsChoosed={shipmentsChoosed}
                setChooseShipments={setChooseShipments}
                // packagingsChoosed={packagingsChoosed}
                // setChoosePackagings={setChoosePackagings}
                loggersChoosed={loggersChoosed}
                setChooseLoggers={setChooseLoggers}
                setChooseAssets={setChooseAssets}
                loggersCount={loggerList.length}
                // packagingsCount={packagingList.length}
                // shipmentsCount={shipmentList.length}
                setTimeRange={setTimeRange}
            />
            <div className={classes.optionTitle}>{t('SENSOR_DATA.TIMEZONE')}</div>
            <SwitchWithLabel
                disabled={disabled}
                size="medium"
                onChange={handleChangeShowInMyLocalTime}
                // setValue={setLocalTimezone}
                switchFirst
                title={t('SENSOR_DATA.SHOW_IN_UTC')}
                value={!localTimezone}
                className={classes.timezoneSwitch}
            />
            {
                entityType === 'shipment' && (
                    (shipmentStart || leaseStart) && (!shipmentEnd && !leaseEnd) && userRoles.SHIPMENT_MANAGEMENT
                ) && (
                    <div>
                        <div className={classes.optionTitle}>{t('SENSOR_DATA.SHOW_UP_TO')}</div>
                        <div className={classes.switchContainer}>
                            <div
                                className={
                                    `${classes.switchOption} ${isCurrentTime ? classes.disabledSwitchOption : ''}`
                                }
                            >
                                {t('COMMON.EXPECTED_LEASE_END')}
                            </div>
                            <Switch
                                checked={isCurrentTime}
                                color="primary"
                                onChange={handleChangeLeaseEndExpected}
                                disabled={leaseEndExpected === null}
                                size="medium"
                            />
                            <div
                                className={
                                    `${classes.switchOption} ${isCurrentTime ? '' : classes.disabledSwitchOption}`
                                }
                            >
                                {t('SENSOR_DATA.NOW')}
                            </div>
                        </div>
                    </div>
                )
            }
            {
                (entityType === 'packagings' || entityType === 'loggers' || entityType === 'assets') && (
                    <QuickHelpTooltip
                        tooltipInfo={{
                            order: 2,
                            text: t('QUICK_HELP.SENSOR_DATA.PERIOD_SELECT'),
                            uid: 'sensorDataPeriodOptions',
                        }}
                    >
                        <div>
                            <div className={classes.optionTitle}>{t('SENSOR_DATA.PERIOD')}</div>
                            <PeriodSelectorWithDatePicker
                                timeRange={timeRange}
                                setTimeRange={setTimeRange}
                                maxDateRange={entityType === 'assets' ? 14 : null}
                            />
                        </div>
                    </QuickHelpTooltip>
                )
            }
            {
                (showInternalTemperature || showAmbientTemperature || showGeolocation
                    // || showDoor
                    || showTemperature) && (
                    <div className={classes.optionTitle}>{t('SENSOR_DATA.DATA_TYPES')}</div>
                )
            }
            {
                showTemperature && (
                    <SwitchWithLabel
                        disabled={disabled || (!ambientTemperature && !internalTemperature)}
                        onChange={handleChangeTemperature}
                        size="medium"
                        // setValue={setTemperature}
                        switchFirst
                        title={t('COMMON.TEMPERATURE')}
                        value={temperature}
                    />
                )
            }
            {
                showInternalTemperature && (
                    <SwitchWithLabel
                        disabled={disabled || (!ambientTemperature && !temperature)}
                        onChange={handleChangeInternalTemperature}
                        // setValue={setInternalTemperature}
                        size="medium"
                        switchFirst
                        title={t('COMMON.INTERNAL_TEMPERATURE')}
                        value={internalTemperature}
                    />
                )
            }
            {
                showAmbientTemperature && (
                    <SwitchWithLabel
                        disabled={disabled || (!internalTemperature && !temperature)}
                        onChange={handleChangeAmbientTemperature}
                        size="medium"
                        // setValue={setAmbientTemperature}
                        switchFirst
                        title={t('COMMON.AMBIENT_TEMPERATURE')}
                        value={ambientTemperature}
                    />
                )
            }
            {
                showGeolocation && (
                    <SwitchWithLabel
                        disabled={disabled || disabledGeolocation}
                        size="medium"
                        onChange={handleChangeGeolocation}
                        // setValue={setGeolocation}
                        switchFirst
                        title={geolocationTitle}
                        value={geolocation}
                    />
                )
            }
            {
                showLocation && entityType !== 'assets' && (
                    <SwitchWithLabel
                        disabled={disabled}
                        onChange={handleChangeLocation}
                        size="medium"
                        // setValue={setLocation}
                        switchFirst
                        title={t('TRACK_AND_TRACE.LOCATION')}
                        value={location}
                    />
                )
            }
            {/* {
                showDoor && (
                    <SwitchWithLabel
                        disabled={disabled}
                        onChange={handleChangeDoor}
                        size="medium"
                        // setValue={setDoor}
                        switchFirst
                        title={t('SENSOR_DATA.DOOR')}
                        value={door}
                    />
                )
            } */}
            <div
                className={classes.optionTitle}
            >
                {disabledGeolocation ? '¹Please maximize a packaging to also show geolocation.' : ''}
            </div>
        </Card>
    );
};

export default Options;
