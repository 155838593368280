export const {
    REACT_APP_SKYCELL_ASSETS_PATH: assetsPath, // 'https://secure-assets.dev.skycell.ch',
} = process.env;

const iconsPath = `${assetsPath}/assets/images/icons/`;

const icons = {
    act_plug: `${iconsPath}act_plug.svg`,
    addCircle: `${iconsPath}addCircle.svg`,
    administration: `${iconsPath}administration.svg`,
    address: `${iconsPath}address.svg`,
    address_blue: `${iconsPath}addressBlue.svg`,
    arrow_small_blue: `${iconsPath}arrow_small_blue.svg`,
    airline: `${iconsPath}airline.svg`,
    airport: `${iconsPath}airport.svg`,
    airport_blue: `${iconsPath}airportBlue.svg`,
    airport_icon: `${iconsPath}airport_icon.svg`,
    airport_icon_orange: `${iconsPath}airport_icon_orange.svg`,
    animal_storage_area: `${iconsPath}animal_storage_area.svg`,
    ansys: `${iconsPath}ansys.svg`,
    approvals: `${iconsPath}approvals.svg`,
    a_gender_person: `${iconsPath}a-gender-person.svg`,
    arrow_down_lg: `${iconsPath}arrow_down_lg.svg`,
    arrow_right_lg: `${iconsPath}arrow_right_lg.svg`,
    arrow_up_round: `${iconsPath}arrow_up_round.svg`,
    asset_logo: `${iconsPath}assetLogo.svg`, // AIRPORT_PALLET GREY
    asset_pallet_black: `${iconsPath}asset_pallet_black.svg`,
    asset_management: `${iconsPath}asset_management.svg`,
    blackStar: `${iconsPath}blackStar.svg`,
    bookings: `${iconsPath}bookings.svg`,
    cage: `${iconsPath}cage.svg`,
    checked_blue: `${iconsPath}checked_blue.svg`,
    checked_green: `${iconsPath}checked_green.svg`,
    checked_yellow: `${iconsPath}checked_yellow.svg`,
    clock_red: `${iconsPath}clock_red.svg`,
    clock_blue: `${iconsPath}clock_blue.svg`,
    clock: `${iconsPath}clock.svg`,
    cross: `${iconsPath}cross.svg`,
    customs: `${iconsPath}customs.svg`,
    customs_grey: `${iconsPath}customs_grey.svg`,
    container_grey: `${iconsPath}container_grey.svg`,
    container_white: `${iconsPath}container_white.svg`,
    container_black: `${iconsPath}container_black.svg`,
    container_box_black: `${iconsPath}container_box_black.svg`,
    dangerous_goods_chemicals: `${iconsPath}dangerous_goods_chemicals.svg`,
    dangerous_goods: `${iconsPath}dangerous_goods.svg`,
    default_packaging_icon: `${iconsPath}/defaultContainer.svg`,
    direction_arrow: `${iconsPath}direction_arrow.svg`,
    document: `${iconsPath}document.svg`,
    download_icon: `${assetsPath}/assets/icons/download_icon.svg`,
    dots_blue: `${iconsPath}dots_blue.svg`,
    dots_round_blue: `${iconsPath}dots_round_blue.svg`,
    dots_round_grey: `${iconsPath}dots_round_grey.svg`,
    email: `${iconsPath}email.svg`,
    finance: `${iconsPath}finance.svg`,
    forwarder: `${iconsPath}forwarder.svg`,
    frozenTempIcon: `${iconsPath}frozenTempIcon.svg`,
    ground_handler: `${iconsPath}ground_handler.svg`,
    healthcare_pharmaceuticals: `${iconsPath}healthcare_pharmaceuticals.svg`,
    hexa_blue: `${iconsPath}hexa_blue.svg`,
    light_hexa_blue: `${iconsPath}light_hexa_blue.svg`,
    light_hexa_blue_filled: `${iconsPath}light_hexa_blue_filled.svg`,
    hexa_blue_dark: `${iconsPath}hexa_blue_dark.svg`,
    hexa_grey: `${iconsPath}hexa_grey.svg`,
    hexa_red: `${iconsPath}hexa_red.svg`,
    hexa_orange: `${iconsPath}hexa_orange.svg`,
    hex_with_cross: `${iconsPath}hex_with_cross.svg`,
    high_value_cargo: `${iconsPath}high_value_cargo.svg`,
    hourglass_pending: `${iconsPath}hourglass_pending.svg`,
    info_blue: `${iconsPath}info_blue.svg`,
    in_progress_blue: `${iconsPath}in_progress_blue.svg`,
    lanes: `${iconsPath}lanes.svg`,
    legend_completed: `${iconsPath}legend_completed.svg`,
    legend_planned: `${iconsPath}legend_planned.svg`,
    legend_waiting_for_approval: `${iconsPath}legend_waiting_for_approval.svg`,
    live_animals: `${iconsPath}live_animals.svg`,
    longArrowRight: `${iconsPath}longArrowRight.svg`,
    location_type: {
        ADDRESS: `${iconsPath}address.svg`,
        AIRPORT: `${iconsPath}airport.svg`,
        SEAPORT: `${iconsPath}seaport.svg`,
    },
    message_icon: `${iconsPath}message_icon.svg`,
    map_location_destination: `${iconsPath}map_location_destination.svg`,
    map_location_via: `${iconsPath}map_location_via.svg`,
    mountains_and_snow: `${iconsPath}mountains_and_snow.svg`,
    my_company: `${iconsPath}my_company.svg`,
    no_icon: `${iconsPath}no_icon.svg`,
    orders: `${iconsPath}orders.svg`,
    pallet: `${iconsPath}pallet.svg`,
    person: `${iconsPath}person.svg`,
    refresh: `${iconsPath}refresh.svg`,
    palm_and_sun: `${iconsPath}palm_and_sun.svg`,
    plane_icon_destination: `${iconsPath}plane_icon_destination.svg`,
    plane_icon_origin: `${iconsPath}plane_icon_origin.svg`,
    page_next: `${iconsPath}page_next.svg`,
    page_previous: `${iconsPath}page_previous.svg`,
    page_next_transparent: `${iconsPath}page_next_transparent.svg`,
    page_previous_transparent: `${iconsPath}page_previous_transparent.svg`,
    packaging_provider: `${iconsPath}packaging_provider.svg`,
    perishables: `${iconsPath}perishables.svg`,
    placeholder_company_logo: `${iconsPath}placeholder_company_logo.svg`,
    prize: `${iconsPath}prize.svg`,
    prize_blue: `${iconsPath}prize_blue.svg`,
    polylinePathOrangeRingIcon: `${iconsPath}polylinePathOrangeRingIcon.svg`,
    rectangle_blue: `${iconsPath}rectangle_blue.svg`,
    rectangle_empty: `${iconsPath}rectangle_empty.svg`,
    rectangle_grey: `${iconsPath}rectangle_grey.svg`,
    rectangle_red: `${iconsPath}rectangle_red.svg`,
    right_arrow_blue: `${iconsPath}right_arrow_blue.svg`,
    right_arrow_grey: `${iconsPath}right_arrow_grey.svg`,
    seaport: `${iconsPath}seaport.svg`,
    seaport_blue: `${iconsPath}seaportBlue.svg`,
    service_providers: `${iconsPath}service_providers.svg`,
    success: `${iconsPath}success.svg`,
    statistics: `${iconsPath}statistics.svg`,
    skycell: '/assets/images/logo/logo_symbol_white.svg',
    skycell_blue: `${iconsPath}skycell_blue.png`,
    star: `${iconsPath}star.svg`,
    stepTypes: {
        air: `${iconsPath}step-types/AIR.svg`,
        confirmation: `${iconsPath}step-types/CONFIRMATION.svg`,
        customs: `${iconsPath}customs_grey.svg`,
        event: `${iconsPath}step-types/EVENT.svg`,
        road: `${iconsPath}step-types/ROAD.svg`,
        sea: `${iconsPath}step-types/SEA.svg`,
        unloading: `${iconsPath}step-types/LOADING.svg`,
        loading: `${iconsPath}step-types/LOADING.svg`,
        ground_handling: `${iconsPath}step-types/GROUND_HANDLING.svg`,
        warehouse: `${iconsPath}step-types/WAREHOUSE.svg`,
    },
    support_icon: `${iconsPath}support_icon.svg`,
    tasks: `${iconsPath}tasks.svg`,
    todo_list: `${iconsPath}todo_list.svg`,
    temperature_widget: `${iconsPath}temperature_widget.svg`,
    temperature: {
        C: `${iconsPath}temperature_C.svg`,
        CRT: `${iconsPath}temperature_CRT.svg`,
        DF: `${iconsPath}temperature_DF.svg`,
        F: `${iconsPath}temperature_F.svg`,
    },
    tempUp: `${iconsPath}tempUp.svg`,
    tempDown: `${iconsPath}tempDown.svg`,
    tetra_blue: `${iconsPath}tetra_blue.svg`,
    light_tetra_blue: `${iconsPath}light_tetra_blue.svg`,
    light_tetra_blue_filled: `${iconsPath}light_tetra_blue_filled.svg`,
    tetra_red: `${iconsPath}tetra_red.svg`,
    tetra_orange: `${iconsPath}tetra_orange.svg`,
    tickmark_blue: `${iconsPath}tickmark_blue.svg`,
    tickmark_green: `${iconsPath}tickmark_green.svg`,
    truck_and_clock: `${iconsPath}truck_and_clock.svg`,
    warning: `${iconsPath}warning.svg`,
    warning_round: `${iconsPath}warning_round.svg`,
    warning_triangle: `${iconsPath}warning_triangle.svg`,
    red_triangle: `${iconsPath}red_triangle.svg`,
    warehouse: `${iconsPath}warehouse.svg`,
    warehouse_airside: `${iconsPath}warehouse.svg`,
    warehouse_landside: `${iconsPath}warehouse.svg`,
    vault: `${iconsPath}vault.svg`,
    quick_help_icon: `${iconsPath}quickHelpIcon.svg`,
    user_guide_icon: `${iconsPath}userGuide.svg`,
};

export default icons;
