import React, { useMemo, CSSProperties, useContext, MouseEventHandler } from 'react';
import { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import { useCalculatedRectStyle } from 'Contexts/QuickHelpContext/useCalculatedRectStyle';
import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import { TOOLTIP_PADDING } from 'Contexts/QuickHelpContext/QuickHelpElement/QuickHelpElement';

type RectangleProps = {
    tooltipInfo: QuickHelpTooltipInfo,
    index: number,
}
const TooltipInfoToRectangle = ({
    tooltipInfo,
    index,
}: RectangleProps) => {
    const {
        childRef,
        customRectSize,
        childOffsetPercent,
        offsetPx,
    } = tooltipInfo || {};
    const {
        enableTooltips,
    } = useContext(QuickHelpContext);

    const childRect = useMemo<DOMRect>(() => {
        const element:HTMLElement = childRef?.current;

        if (!element) return null;

        return element?.getBoundingClientRect();
    }, [tooltipInfo]);

    const rectStyle = useCalculatedRectStyle({
        childRect,
        offsetPx,
        customRectSize,
        childOffsetPercent,
        padding: enableTooltips ? TOOLTIP_PADDING : TOOLTIP_PADDING * 5,
        shouldUpdate: enableTooltips ? 1 : 2,
    });

    return (
        <rect
            key={`rect_${tooltipInfo?.uid}`}
            x={rectStyle?.left}
            y={rectStyle?.top}
            width={rectStyle?.width}
            height={rectStyle?.height}
            style={{
                transition: '100ms ease',
                transitionDelay: `${index * 20}ms`,
            }}
            rx="8"
            fill="black"
        />
    );
};

type Props = {
    tooltipInfos: QuickHelpTooltipInfo[],
    style: CSSProperties,
    onClick: MouseEventHandler<SVGSVGElement>,
}

export const SvgWithCutouts = ({
    tooltipInfos = [],
    style = {},
    onClick,
}: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg" width="400" height="400" style={{
                position: 'absolute',
                width: '100vw',
                height: '100vh',
                zIndex: 10000,
                opacity: 0.5,
                ...style,
            }}
            onClick={onClick}
        >
            <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                fill="rgba(0, 0, 0, 0.6)"
                mask="url(#cutout-mask)"
            />
            <mask id="cutout-mask">
                <rect
                    x="0" y="0" width="100%" height="100%"
                    fill="white"
                />
                {
                    tooltipInfos
                        .map((tooltipInfo, index) => (
                            <TooltipInfoToRectangle
                                key={`rect_${tooltipInfo?.uid}`}
                                tooltipInfo={tooltipInfo}
                                index={index}
                            />
                        ))
                }
            </mask>
        </svg>
    );
};
