import { useMemo } from 'react';
import { TIME_IN_MS } from 'shared-components/constants';
import moment from 'moment';
import { SensorDataItem } from 'dataTypes/SecureBackend/apiResponse';

type Props = {
    temperatureData: SensorDataItem[],
    dateTimeFrom: string,
    dateTimeTo: string,
    inLocalTimeZone: boolean,
}
const useChartLimits = ({
    temperatureData = [],
    dateTimeFrom,
    dateTimeTo,
    inLocalTimeZone,
}: Props) => {
    return useMemo(() => {
        const itemStep = 10 * TIME_IN_MS.minute;

        const globalFromMillis = !inLocalTimeZone ? moment(dateTimeFrom).valueOf()
            : moment.utc(dateTimeFrom).valueOf();
        const globalToMillis = !inLocalTimeZone ? moment(dateTimeTo).valueOf()
            : moment.utc(dateTimeTo).valueOf();

        if (!temperatureData || temperatureData.length === 0) {
            return {
                xMin: globalFromMillis,
                xMax: globalToMillis,
            };
        }

        const sensorDataFirstDate = !inLocalTimeZone ? moment(temperatureData[0].t).valueOf()
            : moment.utc(temperatureData[0].t).valueOf();
        const sensorDataLastDate = !inLocalTimeZone
            ? moment(temperatureData.at(-1).t).valueOf()
            : moment.utc(temperatureData.at(-1).t).valueOf();

        const xMin = (globalFromMillis + itemStep < sensorDataFirstDate)
            ? globalFromMillis
            : sensorDataFirstDate;

        const xMax = (sensorDataLastDate + itemStep < globalToMillis)
            ? globalToMillis
            : sensorDataLastDate;

        return {
            xMin, xMax,
        };
    }, [
        temperatureData,
        dateTimeFrom,
        dateTimeTo,
        inLocalTimeZone,
    ]);
};

export default useChartLimits;
