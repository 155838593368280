import useClasses from 'hooks/useClasses';
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo,
    useState,
} from 'react';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import { Button } from '@mui/material';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { User } from 'dataTypes/SecureBackend/apiResponse';
import { userRoles } from 'hooks/useHasAccess';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { styles } from './UserAccountCard.style';

type Props = {
    disableUserButtonDisabled?: boolean,
    resetPasswordButtonDisabled?: boolean,
    selectedUserInfo: User,
    companyUsers: User[],
    setRequiredUpdateData: Dispatch<SetStateAction<boolean>>,
    admin?: boolean,
}

const UserAccountCard = ({
    disableUserButtonDisabled = true,
    resetPasswordButtonDisabled = false,
    selectedUserInfo = null,
    setRequiredUpdateData,
    companyUsers = [],
    admin = false,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const {
        FlexibleRequest: userRequest,
    } = useSecureBackendEndpoints(`${admin ? 'admin/' : ''}users`).statusNotificationRequests;

    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);

    const isSelectedUserAdmin = useMemo(
        () => selectedUserInfo?.userRoles?.includes(userRoles.CUSTOMER_ADMIN), [selectedUserInfo],
    );
    const isSelectedUserActive = useMemo(
        () => selectedUserInfo?.status === 'ENABLED', [selectedUserInfo],
    );
    const isSelectedUserTheOnlyAdmin = useMemo(() => isSelectedUserAdmin
        && companyUsers.filter(
            user => user.userRoles.includes(userRoles.CUSTOMER_ADMIN),
        ).length === 1,
    [isSelectedUserAdmin, companyUsers]);

    const disableUserButtonText = useMemo(() => {
        return isSelectedUserActive ? t('COMMON.DISABLE_USER') : t('COMMON.ENABLE_USER');
    }, [isSelectedUserActive]);
    const disableUserButtonDescription = useMemo(() => {
        if (isSelectedUserActive) {
            if (isSelectedUserTheOnlyAdmin) {
                return t('COMMON.YOU_CANNOT_DISABLE_USER');
            } else {
                return t('COMMON.DISABLE_USER_DESCRIPTION');
            }
        } else {
            return t('COMMON.USER_ALREADY_DEACTIVATED');
        }
    }, [isSelectedUserActive, isSelectedUserTheOnlyAdmin]);

    const handleStatusChangeButtonClick = useCallback(() => {
        (async () => {
            setLoading(true);

            try {
                const { status = null } = await userRequest('PATCH', `${selectedUserInfo?.id}/status`, {
                    status: isSelectedUserActive ? 'DISABLED' : 'ENABLED',
                });

                setLoading(false);

                if (status === 200) {
                    setRequiredUpdateData(true);
                }
            } catch (error) {
                global.console.log(error);
                setLoading(false);
            }
        })();
    }, [isSelectedUserActive, selectedUserInfo]);

    const handleResetUserButtonClick = useCallback(() => {
        (async () => {
            setLoading(true);

            try {
                await userRequest('PATCH', `${selectedUserInfo?.id}/password-reset`, {
                    status: isSelectedUserActive ? 'DISABLED' : 'ENABLED',
                });

                setLoading(false);
            } catch (error) {
                global.console.log(error);
                setLoading(false);
            }
        })();
    }, [selectedUserInfo]);

    return (
        <CardWithEditableContent
            className={loading ? classes.loading : ''}
            title={t('COMMON.USER_ACCOUNT')}
            onClickEditButton={setEditMode.bind(null, true)}
            onClickUndoButton={setEditMode.bind(null, false)}
            showEditButton={!editMode}
            showUndoButton={editMode}
        >
            <div className={classes.cardContent}>
                <Button
                    className={classes.buttonStyle}
                    disabled={disableUserButtonDisabled || !editMode || isSelectedUserTheOnlyAdmin || loading}
                    onClick={handleStatusChangeButtonClick}
                >
                    {disableUserButtonText}
                </Button>
                <p>
                    {disableUserButtonDescription}
                </p>
                {
                    isSelectedUserActive && false && (
                        <>
                            <Button
                                className={classes.buttonStyle}
                                disabled={resetPasswordButtonDisabled || !editMode || loading}
                                onClick={handleResetUserButtonClick}
                            >
                                {t('COMMON.RESET_PASSWORD')}
                            </Button>
                            <p>
                                {t('COMMON.RESET_PASSWORD_DESCRIPTION')}
                            </p>
                        </>
                    )
                }
            </div>
        </CardWithEditableContent>
    );
};

export default UserAccountCard;
