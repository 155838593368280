// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Theme } from '@mui/material';
import { GatewayInfoDTO, SensorDataItem, SensorDataResponse } from 'dataTypes/SecureBackend/apiResponse';
import { LatLng, LatLngTimeTimestamp } from 'dataTypes/common';
import { TIME_IN_MS } from 'shared-components/constants';
import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ApexOptions } from 'apexcharts';

const LOCATION_LATITUDE = 'LOCATION_LATITUDE';
const LOCATION_LONGITUDE = 'LOCATION_LONGITUDE';

export const cutDateString = (dateString: string) => {
    const dateArr = dateString.split(':');

    dateArr.pop();

    return dateArr.join(':');
};

export interface FixedTooltip {
    enabled: boolean,
    position: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight',
    offsetX: number,
    offsetY: number,
}

export interface SensorLabels {
    loggerTypes: string[],
    positions: string[],
    dataTypes: string[],
}

export interface ApexTooltip {
    icon?: string,
    title?: string,
    index?: number,
    class?: string,
    click?: (chart?: any, options?: any, e?: any) => any,
}

export interface ZoomedDataLimits {
    min: number, max: number
}

export const defaultPolylineOptions = {
    geodesic: true,
    strokeOpacity: 1.0,
    strokeWeight: 3,
    strokeColor: '#009CCF',
};

export const prepareRequestDataTypes = (rawDataTypes: string[]): string[] => {
    const dataTypes = rawDataTypes.reduce((accumulator, type) => {
        if (type === 'GEOLOCATION') {
            return [...accumulator, 'LOCATION_LATITUDE', 'LOCATION_LONGITUDE'];
        }
        if (type === 'LOCATION') {
            return [...accumulator, 'GATEWAY_NUMBER', 'IS_HISTORICAL'];
        }
        return [...accumulator, type];
    }, []);

    return (dataTypes.includes(LOCATION_LATITUDE) && dataTypes.includes(LOCATION_LONGITUDE))
        ? dataTypes
        : [...dataTypes, LOCATION_LATITUDE, LOCATION_LONGITUDE];
};

export const getDataTypeIndexes = (rawDataTypes: string[], dataTypeName: string): number[] => {
    return rawDataTypes.reduce((data, type, index) => {
        return type === dataTypeName ? [...data, index] : data;
    }, []) || [];
};

interface DataLimitIndex { minIndex: number, maxIndex: number }

export const getDataLimitIndexes = (rawData: SensorDataItem[], indexList: number[]): DataLimitIndex[] => {
    // const reversedRawData = [...rawData];
    return indexList.map((index) => {
        let maxIndex = -1;
        const min = rawData.findIndex(({ d }) => d[index]);

        for (let i = rawData.length - 1; i >= 0; i--) {
            if (rawData[i].d[index]) {
                maxIndex = i;
                break;
            }
        }
        return {
            minIndex: min > -1 ? min : Infinity,
            maxIndex,
            // maxIndex: rawData.slice().reverse().findIndex(({ d }) => d[index]),
        };
    });
};

export const getDoorData = (rawData: SensorDataItem[] = [], doorIndexes: number[]): SensorDataItem[] => {
    return rawData?.reduce((data, { t, d }) => {
        const requiredData = doorIndexes.map((index) => d[index]) || [];

        if (requiredData.filter((item) => item !== null).length > 0) {
            return [
                ...data,
                {
                    t,
                    d: requiredData,
                },
            ];
        }
        return data;
    }, []) || [];
};
export const dateToNumber = (utcIsoDateStr: string, inLocalTimeZone = false): number => {
    const date = new Date(utcIsoDateStr);

    const baseMilliseconds = date.getTime();

    if (!inLocalTimeZone) {
        return baseMilliseconds;
    }

    const offset = date.getTimezoneOffset() * TIME_IN_MS.minute;

    return baseMilliseconds - offset;
};

type GetLocationSeriesProps = {
    rawData: SensorDataItem[],
    gatewayInfo: GatewayInfoDTO[],
    locationIndex: number,
    historicalIndex: number,
    constantValue: number,
    inLocalTimeZone: boolean,
    theme: Theme,
    trans: (key: string) => string,
}

export const getLocationSeries = (
    {
        rawData,
        gatewayInfo,
        locationIndex,
        historicalIndex,
        constantValue,
        inLocalTimeZone,
        theme,
        trans,
    }: GetLocationSeriesProps,
): ApexOptions['series'] => {
    const correctedRawData = rawData
        .map(it => {
            if (!it.d[historicalIndex]) return it;
            else {
                const newD = [...it.d];

                newD[locationIndex] = 'not_connected'; // for non-historical data, we don't want to show location
                return {
                    ...it,
                    d: newD,
                };
            }
        });
    const locationData: string[] = correctedRawData.reduce((data, { d }) => {
        const locationName = d[locationIndex] || null;

        return [...data, locationName];
    }, []);

    const locationImeiCodes = [...new Set(locationData)].filter(name => name !== null);
    const getNameByImei = imei => {
        const infoEntry = gatewayInfo
            .find(it => it.gatewayImeiMac?.toLowerCase() === imei?.toLowerCase());

        if (!infoEntry) return `${trans('SENSOR_DATA.NOT_CONNECTED')}`;
        return `${infoEntry.iataCode
            ? `${infoEntry.iataCode } | ` : ''}${infoEntry.area || infoEntry.locationName}`;
    };

    const accumulatedData = correctedRawData
        .reduce((acc, { d, t }) => {
            const imei = d[locationIndex];
            const lastData = acc[acc.length - 1];

            if (!lastData || lastData[0].imei !== imei) {
                acc.push([{ imei, t }]);
            } else {
                lastData.push({ imei, t });
            }
            return acc;
        }, []);

    const colors = [
        theme.palette.primary[100],
        theme.palette.common['beige'],
        theme.palette.primary[200],
        theme.palette.primary[400],
        theme.palette.primary[600],
        theme.palette.primary[800],
        theme.palette.primary[900],
    ];

    return locationImeiCodes.map((code) => {
        const joinedImeiData = accumulatedData.filter((data) => data[0].imei === code).reduce((acc, subArray) => {
            const lastEntry = subArray[subArray.length - 1];

            return [...acc,
                ...subArray.map(({ t }) => ({
                    x: dateToNumber(t, inLocalTimeZone),
                    y: constantValue,
                })),
                {
                    x: dateToNumber(lastEntry.t, inLocalTimeZone) + 10 * TIME_IN_MS.minute - 10,
                    y: constantValue,
                },
                {
                    x: dateToNumber(lastEntry.t, inLocalTimeZone),
                    y: null,
                },
            ];
        }, []);

        return {
            name: getNameByImei(code),
            opacity: 1,
            showValueInTooltip: false,
            color: code === 'not_connected'
                ? theme.palette.secondary[500] : colors.shift() || theme.palette.primary[900],
            data: joinedImeiData,
            position: 'GATEWAY',
            stroke: {
                width: 12,
                curve: 'straight',
            },
        };
    });
};
export const getTemperatureData = (
    rawData: SensorDataItem[],
    temperatureIndexes: number[],
    dataLimitIndexes: DataLimitIndex[],
): SensorDataItem[] => {
    return rawData.reduce((data, { t, d }, dataIndex) => {
        return [
            ...data,
            {
                t,
                d: temperatureIndexes.map((temperatureIndex: number, index: number) => {
                    const { minIndex, maxIndex } = dataLimitIndexes[index];

                    if (dataIndex >= minIndex && dataIndex <= maxIndex) {
                        return d?.[temperatureIndex] || data?.[1]?.d?.[index];
                    }
                    return null;
                }),
            },
        ];
    }, []) || [];
};

export const getSensorLabels = (
    dataTypeIndexes: number[],
    dataTypes: string[],
    loggerTypes: string[],
    positions: string[],
): SensorLabels => {
    return {
        dataTypes: dataTypeIndexes.map((index: number) => dataTypes[index] || ''),
        loggerTypes: dataTypeIndexes.map((index: number) => loggerTypes[index] || ''),
        positions: dataTypeIndexes.map((index: number) => positions[index] || ''),
    };
};

const degreeTypeLimits = {
    lat: { min: -90, max: 90 },
    lng: { min: -180, max: 180 },
};

const isCorrectDegree = (degree, type: ('lat' | 'lng')) => {
    const { max, min } = degreeTypeLimits[type];

    return !Number.isNaN(Number.parseFloat(degree)) && degree >= min && degree <= max;
};

const getTruncedDegree = (degree: number = null) => (
    degree === null ? null : (Math.trunc(degree * 10000) / 10000)
);

export const getFirstExistedLocation = (data: SensorDataItem[], latLngIndexes: LatLng): LatLng => {
    const { d: firstItem = [] } = data.find(({ d }) => d[latLngIndexes.lat] && d[latLngIndexes.lng]) || {};

    return firstItem.length === 0
        ? { lat: null, lng: null }
        : {
            lat: getTruncedDegree(firstItem[latLngIndexes.lat]),
            lng: getTruncedDegree(firstItem[latLngIndexes.lng]),
        };
};

export const getLatLngTimeTimestamp = (
    data: SensorDataItem[],
    latLngIndexes: LatLng,
    firstItem: LatLng,
): LatLngTimeTimestamp[] => {
    return data.reduce((data, { t, d }, index) => {
        if (index === 0) {
            return [{
                time: t,
                timeStamp: new Date(t).getTime(),
                location: firstItem,
            }];
        }

        const location = isCorrectDegree(d[latLngIndexes.lat], 'lat') && isCorrectDegree(d[latLngIndexes.lng], 'lng')
            ? {
                lat: getTruncedDegree(d[latLngIndexes.lat]),
                lng: getTruncedDegree(d[latLngIndexes.lng]),
            }
            : data[index - 1].location;

        return [...data, {
            time: t,
            timeStamp: moment(t).utc(true).valueOf(),
            location,
        }];
    }, []);
};

export const getCoordinates = (rawSensorData: SensorDataResponse): LatLngTimeTimestamp[] => {
    const {
        dataTypes: rawDataTypes = [],
        data = [],
    } = rawSensorData;

    const geolocationIndexes = {
        lat: rawDataTypes.findIndex((type) => type === 'LOCATION_LATITUDE'),
        lng: rawDataTypes.findIndex((type) => type === 'LOCATION_LONGITUDE'),
    };

    if (geolocationIndexes.lat !== -1 && geolocationIndexes.lng !== -1) {
        const firstExistedLocation = getFirstExistedLocation(data, geolocationIndexes);

        return getLatLngTimeTimestamp(data, geolocationIndexes, firstExistedLocation);
    }

    return [];
};

export const getPolylinePath = (coordinates: LatLngTimeTimestamp[], zoomedDataLimits: ZoomedDataLimits): LatLng[] => {
    if (!coordinates || coordinates.length === 0) {
        return [];
    }

    // Established empirically that chart shows elements with indexes around - 13
    const firstElementIndex = zoomedDataLimits.min === null || zoomedDataLimits.min <= coordinates[13]?.timeStamp
        ? 0
        : coordinates.filter(it => it).findIndex(({ timeStamp }) => zoomedDataLimits.min <= timeStamp) - 13;

    const lastElementIndex = zoomedDataLimits.max === null
        ? coordinates.length
        : coordinates.filter(it => it).findIndex(({ timeStamp }) => zoomedDataLimits.max <= timeStamp) - 13;

    return coordinates
        .slice(firstElementIndex, lastElementIndex)
        // .map(({ location }) => location);
        .reduce((data: LatLng[], { location }) => {
            const [lastItem = null] = data.slice(-1);
            const { lat: lastLat = null, lng: lastLng = null } = lastItem || {};

            return location.lat === lastLat && location.lng === lastLng
                ? data
                : [...data, location];
        }, []);
};

export const getCurrentMilliseconds = (inLocalTimeZone = false) => {
    const date = new Date();

    const baseMilliseconds = date.getTime();

    if (inLocalTimeZone) {
        return baseMilliseconds;
    }

    const offset = date.getTimezoneOffset() * TIME_IN_MS.minute;

    return baseMilliseconds + offset;
};
