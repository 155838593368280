const styles = (theme) => ({
    legend: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        boxSizing: 'border-box',
        border: '1px solid transparent',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        display: 'flex',
        padding: '3px 0',
        outline: 'none',
        textOverflow: 'ellipses',
        height: '70px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    title: {
        margin: '0 22px 0 6px',
        textAlign: 'left',
        font: 'normal normal 400 16px/19px Roboto',
        letterSpacing: '0.43px',
        color: 'black',
        opacity: 1,
    },
    lineIcon: {
        width: '75px',
    },
    dotIcon: {
        width: '25px',
    },
    description: {
        margin: '0 28px 0 4px',
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: 'black',
        opacity: 1,
    },
    lastDescription: {
        margin: '0 13px 0 4px',
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
});

export default styles;
