import React from 'react';
import Card from 'shared-components/Card';
import useClasses from '../../hooks/useClasses';

const styles = {
    contentClass: {
        width: '100%',
        height: '100%',
    },
};

const REPORT_ID = process.env.REACT_APP_ASSET_INVENTORY_BI_ID;

// eslint-disable-next-line max-len
const url = `https://app.powerbi.com/reportEmbed?reportId=${REPORT_ID}&autoAuth=true&ctid=8323ebd0-01f5-4d35-8f05-199e4a9752dd`;

const AssetInventory = () => {
    const classes = useClasses(styles);

    return (
        <Card fullHeight contentClass={classes.contentClass}>
            <iframe
                title="Statistics"
                width="100%"
                height="100%"
                src={url}
                allowFullScreen
            />
        </Card>
    );
};

export default AssetInventory;
