import CircularProgress from '@mui/material/CircularProgress';
import useClasses from 'hooks/useClasses';
import React, { useContext, useMemo } from 'react';
import DataPaginationContext from 'Contexts/DataPaginationContext';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import useCustomTranslation from 'hooks/useCustomTranslation';

const styles = (theme: SkycellThemeInterface) => ({
    wrapper: {
        display: 'flex',
        flex: '1',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        padding: '9px',
        borderRadius: 8,
    },
    root: {
        '& .MuiPaginationItem-root': {
            color: theme.palette.primary.deepBlue,
            border: `1px solid ${theme.palette.primary.deepBlue}`,
            borderRadius: '0',
            backgroundColor: 'transparent',
            height: '100%',
            width: 'auto',
            font: 'normal normal normal 14px/17px Roboto',
            margin: '3px 1.5px',
            padding: '0 5px',
        },
        '& .Mui-selected': {
            backgroundColor: `${theme.palette.primary.deepBlue}!important`,
            color: theme.palette.common.white,
        },
    },
    label: {
        marginRight: '19.5px',
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: 'black',
        opacity: 1,
        display: 'inline-flex',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            flex: '100%',
        },
    },
    labelBlue: {
        color: theme.palette.primary.deepBlue,
        display: 'inline-flex',
        paddingLeft: '4px',
        paddingRight: '4px',
    },
    pagination: {
        width: '220px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.common.white,
        boxSizing: 'border-box',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        borderRadius: 8,
        display: 'flex',
        height: '50px',
        padding: '0 9px',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            justifyContent: 'center',
            flexWrap: 'wrap',
            padding: '5px',
            flex: '1',
        },
    },
    wrap: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& ul': {
            flexWrap: 'nowrap',
        },
        '& $label': {
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('lg')]: {
            width: 'min-content',
        },
    },
    noPages: {
        fontSize: '14px',
    },
    shrink: {
        width: 'min-content',
    },
});

type Props = {
    detached?: boolean,
    wrap?: boolean,
    entity?: string,
    shrink?: boolean,
    withoutGeoLocation?: number,
}

const EntityView = ({
    detached = false,
    wrap = false,
    shrink = false,
    entity = 'Assets',
    withoutGeoLocation = 0,
}: Props) => {
    const { t } = useCustomTranslation();
    const {
        totalElements,
        perPage,
        paginationLoading,
    } = useContext(DataPaginationContext);
    const classes = useClasses(styles);

    const total = useMemo(() => {
        return Math.min(perPage, totalElements) - withoutGeoLocation;
    }, [perPage, totalElements, withoutGeoLocation]);

    return (
        <div className={`${detached ? classes.wrapper : ''} ${shrink && classes.shrink}`}>
            <div
                className={[
                    classes.pagination,
                    wrap && classes.wrap,
                    classes.noPages,
                ].join(' ')} aria-disabled
            >
                {
                    paginationLoading ? (
                        <>
                            <CircularProgress />
                        </>
                    ) : (
                        <>
                            {total !== 0 && (
                                <>
                                    <div className={classes.label}>
                                        Showing
                                        {' '}
                                        {total}
                                        {' '}
                                        {entity}
                                    </div>
                                </>
                            )}
                            {
                                total === 0 && t('COMMON.NO_OPTIONS')
                            }

                        </>
                    )
                }
            </div>
        </div>

    );
};

export default EntityView;
