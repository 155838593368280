import React, {
    Dispatch, SetStateAction, useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Button, InputAdornment, TextField, useTheme } from '@mui/material';
import Search from '@mui/icons-material/Search';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import PreviewTable from 'TrackAndTrace/commonComponents/PreviewTable';
import useGetCommonData from 'hooks/useGetCommonData';
import CloseIcon from '@mui/icons-material/Close';
import { AssetsDTO } from 'dataTypes/SecureBackend/apiResponse';
import useClasses from 'hooks/useClasses';
import { ExtractedAssetData, extractAssetData } from '../../../lib';
import tableColumns from './tableColumns';
import styles from './SearchPreviewTable.style';

type Props = {
    setAssetsFromPreviewTable: Dispatch<SetStateAction<ExtractedAssetData[]>>,
    assetsFromPreviewTable: ExtractedAssetData[],
    changeHighlightedText: (newHighlightedText: any) => void,
    isTableView: boolean,
    setQuery?: Dispatch<SetStateAction<string>>,
    query?: string,
}

const SearchPreview = ({
    changeHighlightedText,
    setAssetsFromPreviewTable,
    assetsFromPreviewTable,
    isTableView,
    setQuery,
    query,
} : Props) => {
    const classes = useClasses(styles);
    const theme = useTheme();
    const { t } = useCustomTranslation();
    const textRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<ExtractedAssetData[]>([]);
    const [lastQueryTyped, setLastQueryTyped] = useState('');

    const {
        data: rawAssets,
    } = useGetCommonData<AssetsDTO>('assets/search',
        {
            query: {
                page: 0,
                pageSize: 10,
                q: lastQueryTyped,
            },
            enabled: lastQueryTyped?.length > 2 && !isTableView,
        });

    useEffect(() => {
        const toggle = () => setOpen(false);

        document.addEventListener('click', toggle);
        return () => {
            document.removeEventListener('click', toggle);
        };
    }, []);

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            if (query && query !== lastQueryTyped) {
                setLastQueryTyped(query);
            }

            setOpen(query.length > 2);
        }, 1000);

        return () => clearTimeout(delayDebounce);
    }, [query, lastQueryTyped, isTableView]);

    const handleFocus = () => {
        setOpen(prev => prev || query.length > 0 || assetsFromPreviewTable.length > 0);
    };
    const handleClick = useCallback((e) => e.stopPropagation(), []);

    const queryChanged = (event) => {
        setQuery(event.target.value);
        if (event.target.value) {
            changeHighlightedText(event.target.value.replace(/[\\/#!$%^&*;:{}=\-_`~()]/g, ''));
        } else if (!event.target.value && isTableView) {
            setAssetsFromPreviewTable([]);
        }
    };

    const handleClickShowResults = useCallback(() => {
        setOpen(false);
    }, [query]);
    const handleClickClearSearch = useCallback(() => {
        setAssetsFromPreviewTable([]);
        setOpen(false);
        setQuery('');
        setLastQueryTyped('');
    }, []);

    useEffect(() => {
        if (rawAssets?.resultList) {
            const extractedAssetData = extractAssetData(rawAssets.resultList.slice(0, 50));

            setData(extractedAssetData);
            if (isTableView) {
                setAssetsFromPreviewTable(extractedAssetData);
            }
        }
    }, [rawAssets]);

    const columns = useMemo(() => tableColumns(t), [t]);

    useEffect(() => {
    }, [isTableView]);

    return (
        <div>
            <QuickHelpTooltip
                tooltipInfo={{
                    text: t('QUICK_HELP.TRACK_AND_TRACE.SEARCH_PACKAGING_BY_SERIAL'),
                    order: 2,
                    position: 'right',
                    uid: 'searchBySerial',
                }}
            >
                <div style={{
                    display: 'inline-flex',
                }}
                >
                    <TextField
                        className={classes.input}
                        id="TextFieldInput"
                        onInput={queryChanged}
                        value={query}
                        placeholder={t('COMMON.SEARCH')}
                        ref={textRef}
                        variant="outlined"
                        onFocus={handleFocus}
                        onClick={handleClick}
                        style={{ flex: 1 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search style={{ color: theme.palette.common.black }} />
                                </InputAdornment>
                            ),
                            className: classes.input,
                        }}
                    />
                    <Button
                        className={classes.clearSearchBtn}
                        onClick={() => handleClickClearSearch()}
                        disabled={!query && assetsFromPreviewTable.length === 0}
                    >
                        <CloseIcon />
                    </Button>
                </div>
            </QuickHelpTooltip>
            {
                !isTableView && (
                    <PreviewTable
                        data={data}
                        columns={columns}
                        maskForHighlight={query}
                        onClickButtonClearSearch={handleClickClearSearch}
                        rowLinkTemplate="/asset-management/assets/location/:assetNumber"
                        onCardClick={handleClick}
                        onClickButtonShowResults={handleClickShowResults}
                        isCardOpen={open}
                        textRef={textRef}
                    />
                )
            }
        </div>
    );
};

export default SearchPreview;
