import { useTheme } from '@emotion/react';
import React, { MouseEventHandler, useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import { Edit, Lock, Save, Undo } from '@mui/icons-material';
import Card from 'shared-components/Card';
import { Props as CardProps } from 'shared-components/Card/Card';
import { CircularProgress } from '@mui/material';
import { css } from '@emotion/css';

const generateStyles = (theme) => {
    const visibleIconRow = css({
        opacity: 1,
    });

    const invisibleIconRow = css({
        opacity: 0,
    });

    const cardWithEditableContent = css({
        '&:hover': {
            [`& .${invisibleIconRow}`]: {
                opacity: 1,
            },
        },
    });

    const hoverIconsRow = css({
        display: 'flex',
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '6px 10px',
        whiteSpace: 'nowrap',
        transition: '200ms ease-in-out',
    });

    const icon = css({
        color: theme.palette.secondary[600],
        cursor: 'pointer',
        fontSize: '23px',
        height: '23px',
        width: '23px',
        '&:hover': {
            color: theme.palette.secondary[800],
        },
    });

    return {
        visibleIconRow,
        invisibleIconRow,
        cardWithEditableContent,
        hoverIconsRow,
        icon,
    };
};

type Props = CardProps & {
    onClickEditButton?: MouseEventHandler<HTMLButtonElement>,
    onClickSaveButton?: MouseEventHandler<HTMLButtonElement>,
    onClickUndoButton?: MouseEventHandler<HTMLButtonElement>,
    showEditButton?: boolean,
    showSaveButton?: boolean,
    showUndoButton?: boolean,
    loadingIcon?: boolean,
    showLock?: boolean,
    customLockIcon?: any,
    disabledEditButton?: boolean,
    disabledSaveButton?: boolean,
    disabledUndoButton?: boolean,
};

const CardWithEditableContent = ({
    onClickEditButton = null,
    onClickSaveButton = null,
    onClickUndoButton = null,
    showEditButton = false,
    showSaveButton = false,
    showUndoButton = false,
    loadingIcon = null,
    showLock = false,
    customLockIcon = null,
    disabledEditButton = false,
    disabledSaveButton = false,
    disabledUndoButton = false,
    children = null,
    className = '',
    ...cardProps
} : Props) => {
    const theme = useTheme();
    const classes = generateStyles(theme);

    const showIcons = showSaveButton || showUndoButton;
    const spinner = useMemo(() => {
        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                color: '#393939',
            }}
            >
                <CircularProgress color="inherit" size={20} />
            </div>
        );
    }, []);

    return (
        <Card
            className={`${classes.cardWithEditableContent} ${className}`}
            {...cardProps}
        >
            <div className={`${classes.hoverIconsRow} ${
                showIcons ? classes.visibleIconRow : classes.invisibleIconRow}`}
            >
                {
                    showEditButton && (
                        <IconButton
                            disabled={disabledEditButton}
                            onClick={onClickEditButton}
                            size="small"
                        >
                            <Edit className={classes.icon} />
                        </IconButton>
                    )
                }
                {
                    showUndoButton && (
                        <IconButton
                            disabled={disabledUndoButton}
                            onClick={onClickUndoButton}
                            size="small"
                        >
                            <Undo className={classes.icon} />
                        </IconButton>
                    )
                }
                {
                    showSaveButton && (
                        <IconButton
                            disabled={disabledSaveButton}
                            onClick={onClickSaveButton}
                            size="small"
                        >
                            <Save className={classes.icon} />
                        </IconButton>
                    )
                }
                {
                    showLock && customLockIcon
                }
                {
                    showLock && !customLockIcon && (
                        <IconButton
                            size="small"
                        >
                            <Lock className={classes.icon} />
                        </IconButton>
                    )
                }
                {
                    loadingIcon && spinner
                }
            </div>
            { children }
        </Card>
    );
};

export default CardWithEditableContent;
