import useCurrentUserContext from 'hooks/useCurrentUserContext';

export const userRoles = {
    ASSET_MANAGEMENT: 'ASSET_MANAGEMENT',
    ASSET_PAIRING: 'ASSET_PAIRING',
    CUSTOMER_ADMIN: 'CUSTOMER_ADMIN',
    INSIGHTS: 'INSIGHTS',
    INTELLIGENT_MONITORING: 'INTELLIGENT_MONITORING',
    LANE_MANAGEMENT: 'LANE_MANAGEMENT',
    LOGGER_MANAGEMENT: 'LOGGER_MANAGEMENT',
    PRODUCT_RELEASE: 'PRODUCT_RELEASE',
    SITE_MANAGEMENT: 'SITE_MANAGEMENT',
    SHIPMENT_MANAGEMENT: 'SHIPMENT_MANAGEMENT',
    SKYCELL_ADMIN: 'SKYCELL_ADMIN',
    TEMPERATURE_CHECK: 'TEMPERATURE_CHECK',
    BOOKING_MANAGEMENT: 'BOOKING_MANAGEMENT',
};

const useHasAccess = (some = true) => {
    const { userInfo } = useCurrentUserContext();
    const { assignedRoles = [] } = userInfo;

    return (...accessRoles: string[]): boolean => {
        return accessRoles[some ? 'some' : 'every']((role) => assignedRoles.includes(role));
    };
};

export default useHasAccess;
