import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import { TFunction } from 'i18next';
import { LyingDiff } from 'shared-components/LyingSince';
import LocationHistoryTimeStep from '../LocationHistoryTimeStep';
import LocationHistoryCountry from '../LocationHistoryCountry';
import { DotWithDash } from './DotWithDash';

const customHeaderStyle = { paddingLeft: 13 };

const tableColumns: (t: TFunction) => ColumnsType[] = (t) => [
    {
        Header: ' ',
        accessor: (row, i) => (<DotWithDash row={row} index={i} />),
        dataType: 'custom',
        noBar: true,
        customHeaderStyle,
        customWidth: '20px',
    },
    {
        Header: t('TRACK_AND_TRACE.LOCATION_NAME'),
        accessor: 'locationName',
        dataType: 'text',
        sideFilterKey: 'locationName',
        sideFilterType: 'text',
        noBar: true,
        customHeaderStyle,
    },
    {
        Header: t('TRACK_AND_TRACE.LOCATION_LYING_TIME'),
        accessor: (row) => (!row.locationTimeDiffMinutes && row.locationTimeDiffMinutes !== 0 ? ' '
            : <LyingDiff minutes={row.locationTimeDiffMinutes} isGrey={row.isHistorical} />),
        dataType: 'custom',
        id: 'locationTimeDiffMinutes',
        sortType: (rowA, rowB) => rowB.original.locationTimeDiffMinutes - rowA.original.locationTimeDiffMinutes,
        noBar: true,
        customHeaderStyle,
    },
    {
        Header: t('TRACK_AND_TRACE.AREA'),
        accessor: 'area',
        dataType: 'text',
        sideFilterKey: 'area',
        sideFilterType: 'text',
        noBar: true,
        customHeaderStyle,
    },
    {
        Header: t('TRACK_AND_TRACE.AREA_LYING_TIME'),
        accessor: (row) => (!row.areaTimeDiffMinutes && row.areaTimeDiffMinutes !== 0 ? ' '
            : <LyingDiff minutes={row.areaTimeDiffMinutes} isGrey={row.isHistorical} />),
        dataType: 'custom',
        id: 'areaTimeDiffMinutes',
        sortType: (rowA, rowB) => rowB.original.areaTimeDiffMinutes - rowA.original.areaTimeDiffMinutes,
        noBar: true,
        customHeaderStyle,
    },
    {
        Header: t('COMMON.COUNTRY'),
        accessor: (row) => (!row.timeData?.gateway ? 'N/A'
            : (
                <LocationHistoryCountry
                    latitude={row.timeData.gateway.latitude}
                    longitude={row.timeData.gateway.longitude}
                />
            )),
        dataType: 'custom',
        sideFilterKey: 'country',
        sideFilterType: 'enum',
        noBar: true,
        customHeaderStyle,
    },
    {
        Header: `${t('COMMON.ENTRY')} ${t('COMMON.DATE_TIME')}`,
        accessor: (row) => (
            !row.timeData
                ? ' '
                : (
                    <LocationHistoryTimeStep
                        time={row.timeData.time}
                        localTimezone={row.timeData.localTimezone}
                    />
                )),
        dataType: 'custom',
        sideFilterKey: 'timeData',
        sideFilterType: 'text',
        noBar: true,
        customHeaderStyle,
    },
];

export default tableColumns;
