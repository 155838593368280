import useClasses from 'hooks/useClasses';
import React, { useMemo } from 'react';
import PolygonIcon from 'shared-components/PolygonMarker/PolygonIcon';
import PolygonMarker from 'shared-components/PolygonMarker';
import useCustomTranslation from 'hooks/useCustomTranslation';
import StandalonePagination from 'shared-components/StandalonePagination';
import useScreenSize from 'hooks/useScreenSize';
import { ScreenType } from 'hooks/useScreenSize/useScreenSize';
import { styles } from '../../Loggers.style';

type Props = {
    countOfLoggersWithoutGeolocation: number,
}
const LegendPanel = ({
    countOfLoggersWithoutGeolocation,
}: Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const withoutGeolocationText = useMemo(() => {
        // I18n templates to be used with interpolation
        const text = countOfLoggersWithoutGeolocation === 1
            ? t('TRACK_AND_TRACE.LOGGER_WITHOUT_GEOLOCATION_LEGEND')
            : t('TRACK_AND_TRACE.LOGGERS_WITHOUT_GEOLOCATION_LEGEND');

        return text.replace('#COUNT', `${countOfLoggersWithoutGeolocation}`);
    }, [countOfLoggersWithoutGeolocation]);

    const {
        checkScreenType,
    } = useScreenSize();
    const iconSizes = useMemo(() => (checkScreenType.down(ScreenType.LG) ? 25 : 35), [checkScreenType]);

    return (
        <div>
            <div className={classes.legendContainer}>
                <div className={`${classes.legend} ${classes.legendPoints}`}>
                    <span className={classes.legendText}>{t('COMMON.LEGEND')}</span>
                    <div className={classes.legendPointsWrapper}>
                        <div className={classes.legendPoint}>
                            <PolygonIcon
                                className={classes.legendIcon}
                                type="hexa"
                                theme="light"
                                iconDiameter={iconSizes}
                            />
                            {t('TRACK_AND_TRACE.SKYMIND_LIVE_LOGGER')}
                        </div>
                        <div className={classes.legendPoint}>
                            <PolygonIcon
                                className={classes.legendIcon} type="tetra"
                                iconDiameter={iconSizes}
                                theme="light"
                            />
                            {t('TRACK_AND_TRACE.THIRD_PARTY_LOGGER')}
                        </div>
                        <div className={classes.legendPoint}>
                            <PolygonMarker
                                className={classes.legendIcon}
                                polygonIconType="hexa"
                                count={5}
                                theme="light"
                                iconDiameter={iconSizes}
                            />
                            {t('TRACK_AND_TRACE.LOGGER_GROUP')}
                        </div>
                    </div>
                </div>
                {
                    countOfLoggersWithoutGeolocation > 0 && (
                        <div>
                            <div className={`${classes.legend} ${classes.noGeolocation}`}>
                                {withoutGeolocationText}
                            </div>
                        </div>
                    )
                }
                <StandalonePagination wrap entity="Loggers" shrink />
            </div>

        </div>
    );
};

export default LegendPanel;
