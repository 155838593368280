import { CancelSharp } from '@mui/icons-material';
import { Autocomplete, TextField } from '@mui/material';
import { Logger } from 'dataTypes/SecureBackend/apiResponse';
import useGetCommonData from 'hooks/useGetCommonData';
import React, { useEffect, useState } from 'react';

const LoggerSelector = ({
    classes,
    editedItem,
    field,
    placeholder,
    onChange,
    onFocus,
    invalid,
    mandatory,
    flushable,
}: {
    field: any;
    classes: any;
    editedItem: any;
    onFocus: (fieldId: string) => void;
    placeholder?: string;
    onChange: (value: string) => void;
    invalid: boolean;
    mandatory: boolean;
    flushable: boolean;
}) => {
    const [q, setQ] = useState(editedItem[field.id] || '');
    const [inputValue, setInputValue] = useState(editedItem[field.id] || '');

    const {
        data: loggers = [],
        isLoading,
    } = useGetCommonData<Logger[]>('loggers/search', {
        query: {
            q,
            pageSize: 50,
            page: 0,
        },
        postProcess: (data) => data?.resultList || [],
    });

    useEffect(() => {
        const timerId = setTimeout(() => {
            setQ(inputValue);
        }, 1000);

        return () => clearTimeout(timerId);
    }, [inputValue]);

    return (
        <Autocomplete
            classes={{
                inputRoot: classes.autoCompleteInputRoot,
                root: classes.autoCompleteRoot,
                input: classes.autoCompleteInput,
            }}
            options={loggers.filter(logger => logger.pairingStatus === 'NOT_PAIRED')
                .map((logger) => logger.loggerNumber)}
            size="small"
            value={editedItem[field.id] || ''}
            onFocus={() => onFocus(field.id)}
            style={{
                minWidth: field.minWidth || 'unset',
            }}
            filterOptions={(options) => {
                return options;
            }}
            loading={isLoading}
            onSelect={(e) => {
                // @ts-ignore
                onChange(e.target.value);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    className={[
                        classes.input,
                        invalid ? classes.invalidField : '',
                        mandatory ? classes.mandatory : '',
                    ].join(' ')}
                    variant="outlined"
                    title={editedItem[field.id] || placeholder || ''}
                    placeholder={placeholder || ''}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: flushable
                            ? (
                                <CancelSharp
                                    className={classes.flushIcon}
                                    onClick={() => {
                                        onChange('');
                                        setQ('');
                                    }}
                                />
                            ) : null,
                    }}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            )}
        />
    );
};

export default LoggerSelector;
