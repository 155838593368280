import Button from '@mui/material/Button';
import useScreenSize from 'hooks/useScreenSize';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import React, {
    useMemo,
    useState,
    useEffect,
    useRef,
    Dispatch,
    SetStateAction,
    MouseEventHandler,
    RefObject, useCallback,
} from 'react';
import {
    CompositePolyline,
    LatLngTimeTimestamp,
    TimeRange,
} from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import Map from 'shared-components/Map';
import icons from 'shared-components/icons';
import ApexTemperatureChart, {
    ZoomedDataLimits,
    getPolylinePath,
    defaultPolylineOptions,
} from 'shared-components/ApexTemperatureChart';

import { initialRequestBody } from 'SensorDataCommon/dataTypes';
import PredictedTransportCard from 'LaneManagement/AddLane/Cards/PredictedTransportCard';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { SensorDataItem } from 'dataTypes/SecureBackend/apiResponse';
import useHasAccess from 'hooks/useHasAccess';
import { MilestoneProgress, Milestones } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import useElementSize from 'hooks/useElementSize';
import useClasses from 'hooks/useClasses';
import EditableTransportDialog from './EditableTransportDialog';
import styles from './PackagingTemperature.style';

type Props = {
    cargoId?: number,
    options?: SensorDataRequestBody,
    serialNumber?: string,
    shipmentNumber?: string,
    shipmentId?: string,
    showMap?: boolean,
    temperatureRange?: number[],
    progressUpdateEnabled?: boolean,
    timeRange?: TimeRange,
    showMarkers?: boolean,
    showInUTC?: boolean,
    showMeasuredOnly?: boolean,
    showExpTemp?: boolean,
    showTempRange?: boolean,
    maximized?: boolean,
    handleChange?: (values: any) => void,
    coordinates?: LatLngTimeTimestamp[],
    setCoordinates?: (coordinates: LatLngTimeTimestamp[]) => void,
    zoomedDataLimits?: ZoomedDataLimits,
    setZoomedDataLimits?: Dispatch<SetStateAction<ZoomedDataLimits>>,
    mouseMoveData?: {
        index: number,
        timestamp: number,
      },
      setMouseMoveDataIndex?: (index: number, timestamp?:number) => void,
    onFullscreenClick?: MouseEventHandler<HTMLDivElement>,
    hiddenSeries?: { [key:string]: boolean },
    setHiddenSeries?: Dispatch<SetStateAction<{ [key:string]: boolean }>>,
    noTooltip?: boolean,
    extractTempData?: (serialNumber: string, data: SensorDataItem[], labels: {
        dataTypes: string[],
        loggerTypes: string[],
        positions: string[],
    }) => void,
    exportPng?: (base64: string, aspectRatio: number) => void,
    mapDefaultUI?: boolean,
    mapFullscreenIn?: boolean,
    milestones?: Milestones[],
    setIsSensorDataComplete?: Dispatch<SetStateAction<{
        [packagingNumber: string]: boolean,
    }>>,
    setRequiredOrderDataUpdate?: Dispatch<SetStateAction<boolean>>,
    asAdmin?: boolean,
}

const mapConfig = {
    containerStyle: {
        height: '100%',
        width: '100%',
    },
    zoom: 7,
    backgroundColor: 'unset',
};

const PackagingTemperature = ({
    options = initialRequestBody,
    serialNumber,
    showMap = false,
    temperatureRange,
    cargoId,
    timeRange,
    showMarkers = false,
    showMeasuredOnly = false,
    showExpTemp = false,
    showInUTC = false,
    shipmentId,
    showTempRange = false,
    shipmentNumber = null,
    maximized = false,
    handleChange,
    progressUpdateEnabled = false,
    coordinates,
    setCoordinates,
    zoomedDataLimits,
    setZoomedDataLimits,
    mouseMoveData,
    setMouseMoveDataIndex,
    onFullscreenClick,
    hiddenSeries = null,
    setHiddenSeries,
    noTooltip = false,
    extractTempData,
    exportPng,
    mapDefaultUI = true,
    mapFullscreenIn = false,
    milestones = [],
    setIsSensorDataComplete,
    setRequiredOrderDataUpdate,
    asAdmin = false,
}: Props) => {
    const { t } = useCustomTranslation();
    const [gMap, setGMap] = useState(null);
    const containerRef = useRef(null);
    const [chartRef, setChartRef] = useState<RefObject<HTMLDivElement>>(null);
    const { height: containerHeight } = useElementSize(containerRef);
    const classes = useClasses(styles);
    const [editMilestones, setEditMilestones] = useState(false);
    const [milestoneProgressLoading, setMilestoneProgressLoading] = useState(false);
    const {
        Create: updateProgressRequest,
    } = useSecureBackendEndpoints(`/v2/shipments/${shipmentId}/milestones/action/update-progress`)
        .requests;
    const {
        setErrorStatus,
    } = useStatusStateProcessOptions();
    const {
        width,
    } = useScreenSize();

    useEffect(() => {
        if (serialNumber) setZoomedDataLimits({ min: null, max: null });
    }, [coordinates]);

    const hasAccess = useHasAccess();

    const polylines = useMemo((): CompositePolyline => {
        if (!coordinates || coordinates.length === 0) {
            return {
                path: [],
                options: null,
            };
        }

        const path = getPolylinePath(coordinates, zoomedDataLimits);

        return {
            path,
            options: defaultPolylineOptions,
        };
    }, [coordinates, zoomedDataLimits, options]);

    const coordinatesConcideringShowInUTC = useMemo(() => {
        if (!coordinates || coordinates.length === 0) {
            return [];
        }
        if (!showInUTC) {
            return coordinates;
        }
        const utcOffset = new Date().getTimezoneOffset() * 60 * 1000;

        return coordinates.map((it) => {
            const newIt = { ...it };

            newIt.timeStamp += utcOffset;
            return newIt;
        });
    }, [coordinates, showInUTC]);

    const markerData = useMemo(() => {
        const coordinate = coordinatesConcideringShowInUTC?.find(it => it.timeStamp === mouseMoveData?.timestamp);

        if (!coordinatesConcideringShowInUTC || coordinatesConcideringShowInUTC.length === 0
            || mouseMoveData.index < 0 || !coordinate) {
            return {
                position: null,
                icon: null,
            };
        }
        return {
            position: coordinate.location,
            icon: icons.hex_with_cross,
        };
    }, [
        coordinatesConcideringShowInUTC,
        mouseMoveData,
    ]);

    const tooltipData = useMemo(() => {
        const coordinate = coordinatesConcideringShowInUTC?.find(it => it.timeStamp === mouseMoveData?.timestamp);

        if (!coordinatesConcideringShowInUTC || coordinatesConcideringShowInUTC.length === 0
            || mouseMoveData.index < 0 || !coordinate) {
            return {
                location: null,
                time: '',
            };
        }
        return coordinate;
    }, [
        coordinatesConcideringShowInUTC,
        mouseMoveData,
    ]);
    const [temperatureRangeMin, temperatureRangeMax] = temperatureRange || [null, null];
    const bounds = useMemo(() => {
        const lats = polylines.path.map((item) => item.lat);
        const lngs = polylines.path.map((item) => item.lng);

        return {
            south: lats.length ? Math.min(...lats) : null,
            west: lngs.length ? Math.min(...lngs) : null,
            north: lats.length ? Math.max(...lats) : null,
            east: lngs.length ? Math.max(...lngs) : null,
        };
    }, [polylines]);

    useEffect(() => {
        if (gMap && polylines.path.length > 0 && bounds.east && bounds.north && bounds.south && bounds.west) {
            gMap.fitBounds(bounds);
            gMap.setZoom(gMap.getZoom() - 1);
            setTimeout(() => {
                gMap.setZoom(gMap.getZoom() + 1);
            }, 0);
        }
    }, [bounds, gMap]);

    const handleLoad = (map) => {
        setGMap(map);
    };
    const showTransport = useMemo(() => hasAccess('LANE_MANAGEMENT') && milestones.length > 0,
        [shipmentNumber, hasAccess]);

    const showChangeMilestoneButton = useMemo(() => {
        return showTransport && serialNumber && progressUpdateEnabled;
    }, [showTransport, serialNumber, progressUpdateEnabled]);

    const chartHeight = useMemo(() => {
        if (maximized) {
            if (showMap) {
                if (polylines.path.length === 0) {
                    return containerHeight - (showTransport ? 145 : 35);
                }
                return Math.max(containerHeight / 2 - (showTransport ? 145 : 35), showTransport ? 265 : 300);
            } else {
                return containerHeight - (showTransport ? 150 : 50);
            }
        } else {
            return 400;
        }
    },
    [maximized, showMap, containerHeight, polylines.path]);
    const mapHeight = useMemo(() => (shipmentNumber ? ((maximized
        ? Math.max(containerHeight / 2, 300) : 400)) : 200) - (showChangeMilestoneButton ? 35 : 0),
    [maximized, containerHeight, showTransport, showChangeMilestoneButton]);
    const chartParent = useMemo<HTMLElement>(() => {
        if (containerRef?.current) {
            return containerRef?.current;
        }
    }, [containerRef?.current]);

    const chartPlotArea = useMemo<HTMLElement>(() => {
        if (chartRef?.current && chartParent) {
            return chartParent.querySelector('.apexcharts-grid-borders');
        }
    }, [chartRef?.current, chartParent]);

    // Please, don't be mad about this one. Sometimes life makes us do terrible things.
    const paddingOffset = useMemo<[number, number]>(() => {
        if (chartParent && chartPlotArea) {
            const parentBox:DOMRect = chartParent.getBoundingClientRect();
            const plotBox:DOMRect = chartPlotArea.getBoundingClientRect();

            return [
                Math.abs(plotBox.x - parentBox.x),
                Math.abs(parentBox.right - plotBox.right),
            ];
        } else {
            return [0, 0];
        }
    }, [chartParent, chartPlotArea]);

    const sortedMilestones = [...milestones].sort((a, b) => Number(a.index) - Number(b.index));

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const saveMilestoneProgress = useCallback(async (milestones: MilestoneProgress[], forAll = false) => {
        try {
            setMilestoneProgressLoading(true);
            await updateProgressRequest(milestones);
            setRequiredOrderDataUpdate(prevState => !prevState);
        } catch (e) {
            setErrorStatus(e?.response?.data?.errorMessage || 'something went wrong');
        } finally {
            setMilestoneProgressLoading(false);
        }
    }, [updateProgressRequest]);

    return (
        <div style={{ flex: 1 }} ref={containerRef}>
            {
                serialNumber && (
                    <ApexTemperatureChart
                        handleChange={handleChange}
                        serialNumber={serialNumber}
                        cargoId={cargoId}
                        shipmentId={shipmentId}
                        dateTimeFrom={timeRange?.from}
                        isPredictedExcursion={showTransport && showExpTemp}
                        setIsSensorDataComplete={setIsSensorDataComplete}
                        dateTimeTo={timeRange?.to}
                        temperatureRangeMax={temperatureRangeMax}
                        temperatureRangeMin={temperatureRangeMin}
                        showTempRange={showTempRange}
                        showMeasuredOnly={showMeasuredOnly}
                        showMarkers={showMarkers}
                        shipmentNumber={shipmentNumber}
                        errorPictureSize="medium"
                        requestOptions={options}
                        height={chartHeight}
                        setCoordinates={setCoordinates}
                        setMouseMoveDataIndex={setMouseMoveDataIndex}
                        setZoomedDataLimits={setZoomedDataLimits}
                        inLocalTimeZone={!showInUTC}
                        excursionRange
                        showMapInTooltip
                        errorMessage={t('SENSOR_DATA.NO_SENSOR_DATA_FOR_PACKAGING')}
                        hiddenSeries={hiddenSeries}
                        setHiddenSeries={setHiddenSeries}
                        getSensorDataBySerialNumber={extractTempData}
                        exportPng={exportPng}
                        setChartRef={setChartRef}
                        asAdmin={asAdmin}
                    />
                )
            }
            { showTransport && serialNumber
               && (
                   <PredictedTransportCard
                       className={classes.transportEmbedded}
                       stepsRaw={sortedMilestones}
                       shrink
                       offsetPadding={paddingOffset}
                       showNow
                   />
               )}
            {
                showChangeMilestoneButton && (
                    <Button
                        color="primary"
                        variant="outlined"
                        style={{ marginLeft: paddingOffset[0] }}
                        className={classes.button}
                        onClick={() => setEditMilestones(true)}
                    >
                        {t('TRACK_AND_TRACE.SET_MILESTONES_PROGRESS')}
                    </Button>
                )
            }
            {
                showMap && polylines.path.length > 0 && (
                    <div style={{
                        marginTop: '10px',
                        overflow: 'hidden',
                        height: mapHeight,
                        position: 'relative',
                    }}
                    >
                        <Map
                            mapConfig={mapConfig}
                            separatePolyline={polylines}
                            separateMarker={markerData.position}
                            separateMarkerIcon={markerData.icon}
                            separateTooltip={!noTooltip ? tooltipData : null}
                            handleLoad={handleLoad}
                            inLocalTimeZone={showInUTC}
                            showCenterToPosition={!!shipmentNumber}
                            googleMapOptionsOverride={{
                                disableDefaultUI: !shipmentNumber || !mapDefaultUI,
                                fullscreenControl: false,
                                mapTypeControl: false,
                                streetViewControl: false,
                            }}
                            customFullScreenControl={onFullscreenClick}
                            noMinZoomCap={!shipmentNumber}
                            mapFullscreenIn={mapFullscreenIn}
                        />
                    </div>
                )
            }
            {
                showChangeMilestoneButton && (
                    <EditableTransportDialog
                        initialMilestones={sortedMilestones}
                        open={editMilestones}
                        onSave={saveMilestoneProgress}
                        loading={milestoneProgressLoading}
                        close={() => setEditMilestones(false)}
                        modalWidth={Math.min(width * 0.7, 1200)}
                    />
                )
            }
        </div>
    );
};

export default PackagingTemperature;
