import React, {
    CSSProperties, useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { TimeRange } from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import { PICTURES } from 'shared-components/constants';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import { LayoutContext } from '../Contexts/LayoutContext/index';
import {
    EntitySelectorItem,
} from './dataTypes';
import EntityController from './components/EntityController';
import Options from './components/Options';
import useBreadcrumbsContext from '../hooks/useBreadcrumbsContext';
import ShortPackagingInfo from '../shared-components/ShortPackagingInfo';
import styles from './SensorData.style';

type Props = {
    entitySelectorItem: EntitySelectorItem,
    requestBody: SensorDataRequestBody,
    setRequestBody: (requestBody: SensorDataRequestBody) => void,
    setEntitySelectorItem: (entitySelectorItem: EntitySelectorItem) => void,
    setTimeRange: (timeRange: TimeRange) => void,
    timeRange: TimeRange,
    assetList: EntitySelectorItem[],
    isCurrentTime: boolean,
    assetsFetched: boolean,
    setIsCurrentTime: (isCurrentTime?: boolean) => void,
}

const AssetSensorData = ({
    entitySelectorItem,
    requestBody,
    setRequestBody,
    setEntitySelectorItem,
    setTimeRange,
    timeRange,
    assetList = [],
    isCurrentTime,
    setIsCurrentTime,
    assetsFetched,
} : Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();
    const [localTimezone, setLocalTimezone] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const { setBreadcrumbData } = useBreadcrumbsContext();
    const {
        availableHeight,
    } = useContext(LayoutContext);

    const { entityType } = entitySelectorItem ?? {};

    useEffect(() => {
        setBreadcrumbData('entityType', t(({
            asset: t('TRACK_AND_TRACE.ASSET'),
            shipment: t('COMMON.SHIPMENT'),
            logger: t('TRACK_AND_TRACE.LOGGER'),
        })[entityType || 'assets']));
    },
    [entitySelectorItem]);

    const loadingWrapperStyle: CSSProperties = useMemo(() => (assetsFetched ? {} : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: Number(availableHeight.replaceAll('px', '')) - 20,
    }), [assetsFetched, availableHeight]);

    return (
        <>
            <div className={classes.contentContainer}>
                <div style={loadingWrapperStyle}>
                    <EntityController
                        entitySelectorItem={entitySelectorItem}
                        localTimezone={localTimezone}
                        requestBody={requestBody}
                        showMap={showMap}
                        timeRange={timeRange}
                        isLoading={!assetsFetched}
                    />
                </div>
                <div className={classes.contentRightColumn}>
                    <Options
                        entitySelectorItem={entitySelectorItem}
                        setEntitySelectorItem={setEntitySelectorItem}
                        setTimeRange={setTimeRange}
                        assetList={assetList}
                        disabled={entityType === null}
                        localTimezone={localTimezone}
                        requestBody={requestBody}
                        setRequestBody={setRequestBody}
                        setLocalTimezone={setLocalTimezone}
                        setShowMap={setShowMap}
                        timeRange={timeRange}
                        isCurrentTime={isCurrentTime}
                        setIsCurrentTime={setIsCurrentTime}
                    />
                    {
                        (entityType === 'assets') && (
                            <ShortPackagingInfo
                                model={
                                    entitySelectorItem.additionalData.assetType === 'CONTAINER'
                                        ? t('ASSET_TYPE_LABEL.CONTAINER')
                                        : t('ASSET_TYPE_LABEL.AIRPORT_PALLET')
                                }
                                picture={
                                    entitySelectorItem.additionalData.assetType === 'CONTAINER'
                                        ? PICTURES.prime
                                        : PICTURES.airport_pallet
                                }
                            />
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default AssetSensorData;
