/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useEffect,
    useState,
} from 'react';
import {
    DayOfWeek,
    OperatingHours, weekDaysWithLabelT,
} from 'dataTypes/SecureBackend/apiResponse';
import moment from 'moment';

import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useClasses from 'hooks/useClasses';
import styles from './OperatingHoursCard.style';

const [currentDate] = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString().split('T');
const minTime = moment(`${currentDate}T00:00`);
const maxTime = moment(`${currentDate}T23:59`);

type Props = {
    dayOfWeek: DayOfWeek,
    currentFrom: string,
    currentTo: string,
    operatingHours: OperatingHours[],
    setOperatingHours: (operatingHours: OperatingHours[]) => void,
}

const OperatingHoursRow = ({
    dayOfWeek,
    currentFrom = null,
    currentTo = null,
    operatingHours = [],
    setOperatingHours,
} : Props) => {
    const classes = useClasses(styles);
    const [from, setFrom] = useState(currentFrom ? moment(`${currentDate}T${currentFrom}`) : null);
    const [to, setTo] = useState(currentTo ? moment(`${currentDate}T${currentTo}`) : null);
    const [isContinuousOpen, setIsContinuousOpen] = useState(currentFrom === '00:00' && currentTo === '23:59');
    const [IsClosed, setIsClosed] = useState(currentFrom === null && currentTo === null);

    const { t } = useCustomTranslation();
    const handleChangeTimeFrom = (value) => {
        if (value && to && value.valueOf() >= to.valueOf()) {
            setFrom(null);
        } else {
            setFrom(value);
        }
    };

    const handleChangeTimeTo = (value) => {
        if (from && value && from.valueOf() >= value.valueOf()) {
            setTo(null);
        } else {
            setTo(value);
        }
    };

    const handleChangeIsContinuousOpen = (event) => {
        setIsContinuousOpen(event.target.checked);
        setFrom(minTime);
        if (event.target.checked) {
            setIsClosed(false);
            setFrom(minTime);
            setTo(maxTime);
        }
    };
    const handleChangeIsClosed = (event) => {
        setIsClosed(event.target.checked);
        if (event.target.checked) {
            setIsContinuousOpen(false);
            setFrom(null);
            setTo(null);
        }
    };

    useEffect(() => {
        try {
            const fromTime = from ? from.format('HH:mm') : null;
            const toTime = to ? to.format('HH:mm') : null;

            if (fromTime && toTime && from.valueOf() <= to.valueOf()) {
                const updatedData: OperatingHours[] = [
                    ...(operatingHours.filter(item => item.dayOfWeek !== dayOfWeek)),
                    {
                        dayOfWeek,
                        from: fromTime,
                        to: toTime,
                    },
                ];

                setOperatingHours(updatedData);
                if (fromTime === '00:00' && toTime === '23:59') {
                    setIsContinuousOpen(true);
                }
            } else {
                setOperatingHours(operatingHours.filter(item => item.dayOfWeek !== dayOfWeek));
            }
        } catch (error) {
            setOperatingHours(operatingHours.filter(item => item.dayOfWeek !== dayOfWeek));
        }
    }, [from, to]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className={classes.rowGrid}>
                <div className={classes.title}>
                    {`${weekDaysWithLabelT(t)[dayOfWeek]}\
                 ${t('SERVICE_PROVIDERS.FROM_TO')}`}
                </div>
                <div className={classes.title}>
                    {
                        dayOfWeek === 'MONDAY'
                            ? t('SERVICE_PROVIDERS.24_H_OPEN') : ''
                    }
                </div>
                <div className={classes.title}>
                    {
                        dayOfWeek === 'MONDAY'
                            ? t('SERVICE_PROVIDERS.CLOSED') : ''
                    }
                </div>
            </div>
            <div className={classes.equalRowGrid}>
                <TimePicker
                    ampm={false}
                    slotProps={{
                        dialog: { className: classes.timePicker },
                        textField: {
                            className: classes.textFieldText,
                        },
                    }}
                    disabled={IsClosed}
                    disableOpenPicker
                    format="HH:mm"
                    minutesStep={5}
                    onChange={handleChangeTimeFrom}
                    value={from}
                    views={['hours', 'minutes']}
                />
                <TimePicker
                    ampm={false}
                    slotProps={{
                        dialog: { className: classes.timePicker },
                        textField: {
                            className: classes.textFieldText,
                        },
                    }}
                    disabled={IsClosed}
                    disableOpenPicker
                    format="HH:mm"
                    minutesStep={5}
                    onChange={handleChangeTimeTo}
                    value={to}
                    views={['hours', 'minutes']}
                />
                <Switch
                    checked={isContinuousOpen}
                    color="primary"
                    disabled={IsClosed}
                    onChange={handleChangeIsContinuousOpen}
                    size="small"
                />
                <Switch
                    checked={IsClosed}
                    color="primary"
                    onChange={handleChangeIsClosed}
                    size="small"
                />
            </div>
        </LocalizationProvider>
    );
};

export default OperatingHoursRow;
