const styles = () => ({
    input: {
        border: 'none',
        outline: 'none',
        width: '100%',
    },
    fieldWrapper: {
        '& .flag-dropdown': {
            display: 'none',
        },
        '& .special-label': {
            display: 'none',
        },
        '& input.form-control': {
            paddingLeft: '2px',
        },
        '& input.invalid-number': {
            background: '#FFE5E5 0% 0% no-repeat padding-box',
            borderRadius: '3px',
            opacity: 1,
        },
    },
    disabled: {
        pointerEvents: 'none',
        '& *::before': {
            content: '""',
        },
        '& *::after': {
            content: '""',
        },
    },
    noMargin: {
        margin: 0,
    },
});

export default styles;
