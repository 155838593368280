import {
    createContext, CSSProperties,
} from 'react';

const initialState = {
    availableHeight: '100vh',
    setMenuTabs: () => {},
    menuTabs: [],
    setCustomPageTitle: () => {},
};

export type Tab = {
    title: string,
    link?: string,
    onClick?: Function,
    iconSrc?: string,
    activePage?: boolean,
    iconStyle?: CSSProperties,
    hide?:boolean,
}

export type LayoutContextState = {
    availableHeight: string,
    setMenuTabs: (menuTabs?: Tab[]) => void,
    menuTabs: Tab[],
    setCustomPageTitle: Function,
}

export const LayoutContext = createContext<LayoutContextState>(initialState);
