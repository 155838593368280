import useClasses from 'hooks/useClasses';
import React, { useState } from 'react';
import { styles } from './EnergyLevelBar.style';

type Props = {
    energyLevel?: number,
    threshold?: number,
}
const EnergyLevelBar = ({
    energyLevel,
    threshold,
}: Props) => {
    const classes = useClasses(styles);
    const [progress, setProgress] = useState(Number(((energyLevel / 1) * 100).toFixed(0)));

    const clickHandler = () => {
        setProgress(Number(((energyLevel / 1) * 100).toFixed(0)));
    };

    const progressStyle = {
        width: `${progress}%`,
    };

    return (
        <div onClick={clickHandler}>
            <div className={Number(((energyLevel / 1) * 100).toFixed(0)) < threshold
                ? classes.shellRed : classes.shell}
            >
                <div
                    className={Number(((energyLevel / 1) * 100).toFixed(0)) < threshold
                        ? classes.barRed : classes.bar} style={progressStyle}
                >
                    <span style={{
                        paddingLeft: Number(((energyLevel / 1) * 100).toFixed(0)) <= 0
                            ? '0px' : Number(((energyLevel / 1) * 100).toFixed(0)) === 100 ? '57px'
                                : Number(((energyLevel / 1) * 100).toFixed(0)) >= 64 ? '64px'
                                    : `${Number(((energyLevel / 1) * 100).toFixed(0))}px`,
                    }}
                    >
                        { `${progress }%` }
                    </span>
                </div>
            </div>
        </div>
    );
};

export default EnergyLevelBar;
