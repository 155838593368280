import { useEffect, useState } from 'react';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { GenericCargo } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import { adminApi } from '../../utils/stringTool';

const useFetchLoggerNumbers = (shipmentId: string, cargo: GenericCargo[], asAdmin: boolean = false) => {
    const [loggerNumbers, setLoggerNumbers] = useState<{ [serialNumber: string]: string[] }>({});

    const { Get: getShipmentCargo } = useSecureBackendEndpoints('').requests;

    useEffect(() => {
        const fetchAllLoggerNumbers = async () => {
            const results = await Promise.all(cargo.map(async (item) => {
                try {
                    const endpoint = adminApi(
                        `v2/shipments/${shipmentId}/cargo/${item.id}/sensor-data-for-default-logger-types`,
                        asAdmin);
                    const response = await getShipmentCargo(endpoint);
                    const loggerNumbers = (response.data?.loggerNumbers || []).map((loggerNumber, i) => ({
                        loggerNumber,
                        position: response.data?.positions[i],
                    }));

                    return { serialNumber: item.packaging.serialNumber, loggerNumbers };
                } catch (error) {
                    global.console.error('Error fetching logger numbers:', error);
                    return { serialNumber: item.packaging.serialNumber, loggerNumbers: [] };
                }
            }));

            const loggerNumbersMap = results.reduce((acc, { serialNumber, loggerNumbers }) => {
                acc[serialNumber] = loggerNumbers;
                return acc;
            }, {});

            setLoggerNumbers(loggerNumbersMap);
        };

        if (shipmentId && cargo.length > 0) {
            fetchAllLoggerNumbers();
        }
    }, [shipmentId, cargo]);

    return { loggerNumbers };
};

export default useFetchLoggerNumbers;
