import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Polyline } from '@react-google-maps/api';
import BaseMap from 'TrackAndTrace/commonComponents/BaseMap';
import LoggerMarker from 'TrackAndTrace/Loggers/components/LoggerMarker';
import { LatLng } from '../../../dataTypes/common';

type Props = {
    polylines: any[],
    selected?: any,
    widthPercent: number,
    height: string,
    dragTo?: LatLng
}

const LocationHistoryMap = ({
    polylines = [],
    selected = null,
    widthPercent,
    height,
    dragTo,
}: Props) => {
    const theme = useTheme();
    const [gMap, setGMap] = useState(null);
    const [, setBounds] = useState<number[]>([]);

    useEffect(() => {
        if (dragTo && dragTo.lng && dragTo.lat && gMap) {
            const targetLocation = new window.google.maps.LatLng(dragTo.lat, dragTo.lng);

            gMap.panTo(targetLocation);

            let currentZoom = gMap.getZoom();
            const targetZoom = 15;
            const zoomInterval = setInterval(() => {
                if (currentZoom < targetZoom) {
                    currentZoom += 0.5;
                    gMap.setZoom(currentZoom);
                } else {
                    clearInterval(zoomInterval);
                }
            }, 30);
        }
    }, [dragTo, gMap]);
    return (
        <div style={{
            width: `${widthPercent}%`,
            height,
        }}
        >
            <BaseMap
                setBounds={setBounds}
                gMap={gMap}
                setGMap={setGMap}
                mapContainerStyle={{
                    width: '100%',
                    height,
                    zIndex: 1,
                }}
            >
                {
                    polylines.map((line, ind) => {
                        return (
                            <Polyline
                                key={`polyline${ind}_${line.lng}_${line.lng}`}
                                path={line}
                                options={{
                                    strokeColor: theme.palette.primary['deepBlue'],
                                }}
                            />
                        );
                    })
                }
                {selected && selected?.lastMeasuredLongitude && (
                    <LoggerMarker
                        key={selected}
                        loggerGroup={[selected]}
                        showLoggerTypes={false}
                        showLastConnection={false}
                        onHover={() => {}}
                        isSelected={false}
                    />
                )}
            </BaseMap>
        </div>
    );
};

export default LocationHistoryMap;
