import React, { useState } from 'react';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useHasAccess from 'hooks/useHasAccess/useHasAccess';
import { userRoles } from 'hooks/useHasAccess';
import useClasses from 'hooks/useClasses';
import EditMode from './EditMode';
import ReadMode from './ReadMode';
import styles from './CompanyCard.style';

type Props = {
    className?: string,
    currentCompanyLogo?: string,
    currentCompanyName?: string,
    currentCompanyType?: string,
    currentEditedCard?: string,
    companyTypeSelectable?: boolean,
    currentWebsite?: string,
    saveInfo?: () => void,
    setCurrentEditedCard?: (currentEditedCard: string) => void,
    setFilter?: (filter) => void,
    setLogoFormData?: (formData: FormData) => void,
    type?: 'new' | 'editable' | 'readOnly',
    nameCheckInProgress?: boolean,
    setNameCheckInProgress?: (bool?: boolean) => void,
    setAuthorizesDataSharing?: (arg?: boolean) => void,
    currentAuthorizesDataSharing?: boolean,
    authorizesDataSharing?: boolean,
}

const cardName = 'CompanyCard';

const CompanyCard = ({
    className = '',
    currentCompanyLogo = '',
    currentCompanyName = '',
    currentCompanyType = '',
    currentEditedCard = null,
    currentWebsite = '',
    saveInfo = () => {
    },
    setCurrentEditedCard = () => {
    },
    setFilter = () => {
    },
    setLogoFormData,
    type = 'readOnly',
    nameCheckInProgress = false,
    setNameCheckInProgress = () => {
    },
    companyTypeSelectable = false,
    setAuthorizesDataSharing = null,
    currentAuthorizesDataSharing = false,
    authorizesDataSharing = false,
}: Props) => {
    const classes = useClasses(styles);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useCustomTranslation();
    const hasAccess = useHasAccess();
    const isCurrentUserAdmin = hasAccess(userRoles.CUSTOMER_ADMIN, userRoles.SKYCELL_ADMIN);

    const handleClickEditButton = () => {
        setCurrentEditedCard(cardName);
        setEditMode(true);
    };

    const handleClickSaveButton = async () => {
        setLoading(true);
        await saveInfo();
        setEditMode(false);
        setLoading(false);
        setCurrentEditedCard(null);
    };

    const handleClickUndoButton = () => {
        setCurrentEditedCard(null);
        setFilter({
            companyName: currentCompanyName,
            companyType: currentCompanyType,
            website: currentWebsite,
        });
        setEditMode(false);
    };

    return (
        <CardWithEditableContent
            className={`${className} ${loading ? classes.loading : ''}`}
            onClickEditButton={handleClickEditButton}
            onClickSaveButton={handleClickSaveButton}
            onClickUndoButton={handleClickUndoButton}
            showEditButton={type === 'editable' && isCurrentUserAdmin && !editMode}
            showSaveButton={type === 'editable' && editMode && !nameCheckInProgress}
            showUndoButton={type === 'editable' && editMode}
            loadingIcon={nameCheckInProgress}
            disabledEditButton={currentEditedCard !== null && currentEditedCard !== cardName}
            title={t('COMMON.COMPANY')}
        >
            {
                type === 'new' || (type === 'editable' && editMode)
                    ? (
                        <EditMode
                            currentCompanyName={currentCompanyName}
                            currentCompanyType={currentCompanyType}
                            currentWebsite={currentWebsite}
                            setFilter={setFilter}
                            setLogoFormData={setLogoFormData}
                            typeSelectable={companyTypeSelectable}
                            setNameCheckInProgress={setNameCheckInProgress}
                            setAuthorizesDataSharing={setAuthorizesDataSharing}
                            currentAuthorizesDataSharing={currentAuthorizesDataSharing}
                            authorizesDataSharing={authorizesDataSharing}
                        />
                    )
                    : (
                        <ReadMode
                            currentCompanyName={currentCompanyName}
                            currentCompanyType={currentCompanyType}
                            currentWebsite={currentWebsite}
                            currentCompanyLogo={currentCompanyLogo}
                            setAuthorizesDataSharing={setAuthorizesDataSharing}
                            currentAuthorizesDataSharing={currentAuthorizesDataSharing}
                        />
                    )
            }
        </CardWithEditableContent>
    );
};

export default CompanyCard;
