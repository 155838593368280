import useCacheContext from 'hooks/useCacheContext';
import { useCallback } from 'react';

const FILTER = 'filter';

export const PAGES = {
    GENERIC_SHIPMENTS: 'TrackAndTrace-GenericShipments',
    LOGGERS: 'TrackAndTrace-Loggers',
    ASSETS: 'assets',
};

const useFilter = (page: string) => {
    const { getCacheValue, setCacheValue } = useCacheContext();

    const getFilter = useCallback(() => {
        const filter = getCacheValue(FILTER) || {};

        return filter[page] || null;
    }, [page]);

    const setFilter = useCallback((filter: object) => {
        const newCacheFilter = { [page]: filter };

        setCacheValue(FILTER, newCacheFilter);
    }, [page]);

    return { getFilter, setFilter };
};

export default useFilter;
