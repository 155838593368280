import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import useCachedQueryRequest from 'hooks/useCachedQueryRequest';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { useMemo } from 'react';
import { getRequestParametersString } from 'SensorDataCommon/lib';
import { prepareRequestDataTypes } from 'shared-components/ApexTemperatureChart/lib';
import { adminApi } from '../../../../utils/stringTool';

type Props = {
    requestOptions?: SensorDataRequestBody,
    requestType: 'packagings' | 'loggers' | 'assets',
    dateTimeFrom:string,
    dateTimeTo:string,
    shipmentNumber: string,
    serialNumber: string,
    entityId: string | number,
    cargoId?: number,
    shipmentId?: string,
    currentDate: any,
    isPredictedExcursion?: boolean,
    showMeasuredOnly?: boolean,
    isGeneric?: boolean,
    asAdmin?: boolean,
    enabled?: boolean,
}
const useLoadedData = ({
    requestOptions,
    requestType,
    dateTimeFrom,
    dateTimeTo,
    shipmentNumber,
    serialNumber,
    entityId,
    cargoId,
    shipmentId,
    isPredictedExcursion = false,
    showMeasuredOnly = false,
    currentDate,
    enabled = true,
    isGeneric = false,
    asAdmin = false,
}: Props) => {
    const {
        FlexibleRequest: getShipmentSensorData,
    } = useSecureBackendEndpoints(adminApi(isGeneric ? '' : 'shipment', asAdmin)).requests;
    const { FlexibleRequest: getLoggerSensorData } = useSecureBackendEndpoints(adminApi('loggers', asAdmin)).requests;
    const { FlexibleRequest: getAssetSensorData } = useSecureBackendEndpoints(adminApi('assets', asAdmin)).requests;

    const bakedRequestOptions = useMemo(() => {
        const {
            positions = [],
            loggers = [],
            dataTypes: rawDataTypes = [],
        } = requestOptions;

        const dataTypesForRequest = prepareRequestDataTypes(rawDataTypes);

        if (requestOptions.dataTypes.length === 0) return null;
        switch (requestType) {
        case 'assets':
            return {
                from: dateTimeFrom,
                to: dateTimeTo,
            };
        case 'loggers':
            return {
                useStandardizedTimestamps: true,
                from: dateTimeFrom,
                to: dateTimeTo,
                dataTypes: [...(new Set(['TEMPERATURE', ...dataTypesForRequest]))],
                loggers,
                positions,
            };
        case 'packagings':
            return {
                timestampFormat: 'ISO',
                dataTypes: dataTypesForRequest,
                positions,
            };
        }
    }, [requestOptions, dateTimeFrom, dateTimeTo, requestType]);

    const shouldRequest = useMemo(() => !!bakedRequestOptions && ((dateTimeFrom !== null
        && dateTimeTo !== null) || requestType === 'packagings')
        && (bakedRequestOptions?.positions?.length > 0 || requestType !== 'packagings')
&& (bakedRequestOptions?.dataTypes?.includes('TEMPERATURE') || requestType !== 'loggers'),
    [dateTimeFrom, dateTimeTo, bakedRequestOptions]);

    const {
        status,
        isError,
        data: rawSensorData = null,
    } = useCachedQueryRequest({
        key: {
            bakedRequestOptions,
            requestType,
            shipmentNumber,
            serialNumber,
            dateTimeFrom,
            dateTimeTo,
            entityId,
            cargoId,
            shipmentId,
            currentDate,
            isPredictedExcursion,
            showMeasuredOnly,
            isGeneric,
        },
        request: () => {
            switch (requestType) {
            case 'assets':
                return getAssetSensorData(
                    'GET',
                        `${entityId}/sensor-data?${getRequestParametersString(bakedRequestOptions)}`,
                );
            case 'loggers':
                return getLoggerSensorData('GET',
                        `${serialNumber}/sensor-data?${getRequestParametersString(bakedRequestOptions)}`);
            case 'packagings':
                if (isPredictedExcursion) {
                    return getShipmentSensorData('GET',
                        `v2/shipments/${shipmentId}/cargo/${cargoId}/sensor-data-with-prediction`);
                } else if (showMeasuredOnly) {
                    return getShipmentSensorData('GET',
                            `v2/shipments/${shipmentId}/cargo/${
                            cargoId}/sensor-data?splitMeasurementsAndPredictions=true`);
                } else {
                    return getShipmentSensorData('GET',
                            `v2/shipments/${shipmentId}/cargo/${
                            cargoId}/sensor-data?splitMeasurementsAndPredictions=false`);
                }
            }
        },
        onCompleted: response => response?.data || null,
        options: {
            enabled: shouldRequest && enabled,
            retry: false,
        },
    });

    return {
        status,
        isError,
        rawSensorData,
    };
};

export default useLoadedData;
