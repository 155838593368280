import React from 'react';
import { statuses } from 'TrackAndTrace/GenericShipments/lib';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import moment from 'moment';
import icons from 'shared-components/icons';
import { STATUS } from 'Administration/Shipments/lib';
import InternalDataCheck from './components/InternalDataCheck/InternalDataCheck';

const marginBottom = 8;

const tableColumns: (t) => ColumnsType[] = (t) => {
    return [
        {
            Header: t('TRACK_AND_TRACE.ORDER'),
            accessor: (row) => {
                const { externalId, dataCompleteCheckResult } = row;
                const isRed = dataCompleteCheckResult?.dataCompleteCheckForCargos?.some(
                    cargo => cargo.internalDataCompleteStatus === STATUS.NOT_RECOVERABLE,
                );

                return (
                    <div style={isRed ? { color: '#B92C2C' } : {}}>
                        {externalId}
                    </div>
                );
            },
            dataType: 'highlightedText',
        },
        {
            Header: t('TRACK_AND_TRACE.STATUS'),
            accessor: ({ status }) => statuses[status],
            dataType: 'text',
        },
        {
            Header: t('COMMON.FROM'),
            accessor: 'originAirport',
            dataType: 'text',
        },
        {
            Header: t('COMMON.TO'),
            accessor: 'destinationAirport',
            dataType: 'text',
        },
        {
            Header: t('TRACK_AND_TRACE.TITLE_SHIPMENT_DATA_OWNER'),
            accessor: 'dataOwner',
            dataType: 'text',
        },
        {
            Header: t('COMMON.PACKAGINGS'),
            accessor: (row) => row?.cargo.map((item, i) => {
                const { dataCompleteCheckResult } = row;
                const packagingDataCompleteCheckResult = dataCompleteCheckResult?.dataCompleteCheckForCargos?.find(
                    cargo => cargo.packagingNumber === item.packaging.serialNumber,
                );
                const isRed = packagingDataCompleteCheckResult?.internalDataCompleteStatus === STATUS.NOT_RECOVERABLE;

                return (
                    <div style={{
                        marginBottom: i !== row?.cargo.length - 1 ? marginBottom : 0,
                        ...(isRed ? { color: '#B92C2C' } : {}),
                    }}
                    >
                        {item.packaging.serialNumber}
                    </div>
                );
            }),
            dataType: 'highlightedText',
            customCellStyle: {
                whiteSpace: 'pre-wrap',
                maxWidth: '230px',
            },
            disableSortBy: true,
        },
        {
            Header: t('COMMON.INTERNAL_DATA_CHECK'),
            accessor: ({ dataCompleteCheckResult }) => (
                dataCompleteCheckResult?.dataCompleteCheckForCargos
                    ? (
                        <InternalDataCheck
                            cargos={dataCompleteCheckResult?.dataCompleteCheckForCargos}
                            marginBottom={marginBottom}
                        />
                    )
                    : null
            ),
            dataType: 'text',
        },
        {
            Header: t('COMMON.DATA_CHECK_TIMESTAMP_UTC'),
            accessor: ({ dataCompleteCheckResult }) => (
                dataCompleteCheckResult?.dataCompleteCheckForCargos?.map(
                    ({ executionTimestamp }, i, arr) => (
                        <div style={{ marginBottom: i !== arr.length - 1 ? marginBottom : 0 }}>
                            {executionTimestamp ? moment(executionTimestamp).format('DD-MM-YYYY HH:mm') : ' '}
                        </div>
                    )) || ' '
            ),
            dataType: 'text',
        },
        {
            Header: t('COMMON.DATA_CHECK_OVERRIDE'),
            accessor: ({ overrideOptions }) => (
                overrideOptions?.override
                    ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img alt="tasks" src={icons.checked_green} style={{ marginRight: 8 }} />
                            {t('COMMON.OVERRIDDEN')}
                        </div>
                    )
                    : ' '
            ),
            dataType: 'text',
            customCellStyle: {
                alignItems: 'center',
            },
        },
    ].filter(Boolean); // This filters out any falsy values in the array
};

export default tableColumns;
